// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/orders.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "../google/protobuf/any";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetOrderRequest
 */
export interface GetOrderRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetOrderResponse
 */
export interface GetOrderResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.OrderItem item = 1;
     */
    item?: OrderItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.OrderItem
 */
export interface OrderItem {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp orderedAt = 2;
     */
    orderedAt?: Timestamp;
    /**
     * @generated from protobuf field: bool completed = 3;
     */
    completed: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Any orderedItem = 5;
     */
    orderedItem?: Any;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetOrderRequest$Type extends MessageType<GetOrderRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetOrderRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetOrderRequest
 */
export const GetOrderRequest = new GetOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrderResponse$Type extends MessageType<GetOrderResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetOrderResponse", [
            { no: 1, name: "item", kind: "message", T: () => OrderItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetOrderResponse
 */
export const GetOrderResponse = new GetOrderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderItem$Type extends MessageType<OrderItem> {
    constructor() {
        super("wuxiaworld.api.v2.OrderItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "orderedAt", kind: "message", T: () => Timestamp },
            { no: 3, name: "completed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "orderedItem", kind: "message", T: () => Any }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.OrderItem
 */
export const OrderItem = new OrderItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Orders
 */
export const Orders = new ServiceType("wuxiaworld.api.v2.Orders", [
    { name: "GetOrder", options: {}, I: GetOrderRequest, O: GetOrderResponse }
]);
