import {
    GetUserStreaksRequest,
    StreakType,
    type UserStreakItem,
    UserStreakItem_State,
} from '@app/_proto/Protos/user_streaks';
import { UserStreaksClient } from '@app/_proto/Protos/user_streaks.client';
import { ReactComponent as CultivationKeyIcon } from '@app/assets/cultivation-key.svg';
import StreakDayItem from '@app/components/user-streak/StreakDayItem';
import StreakLevelDetails from '@app/components/user-streak/StreakLevel';
import VouchersIntroDialog from '@app/components/user-streak/VouchersIntroDialog';
import useCompleteMyLoginMission from '@app/domains/common/hooks/useCompleteMyLoginMission';
import useMyVouchers from '@app/domains/common/hooks/useMyVouchers';
import useTimeCountdown from '@app/domains/common/hooks/useTimeCountdown';
import { useGrpcApiWithQuery } from '@app/libs/api';
import { useAuth } from '@app/libs/auth';
import { useGrpcRequest } from '@app/libs/grpc';
import { useLocalStorage } from '@app/libs/hooks';
import { getStreakDays } from '@app/utils/utils';
import { Button, Dialog, DialogContent, Portal } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

type VoucherCountNewOldValue = {
    oldValue: number | undefined;
    newValue: number | undefined;
};

export default function UserStreaksDialog() {
    const queryClient = useQueryClient();

    const [streakDialogOpen, setStreakDialogOpen] = useState(false);
    const [checkedIn, setCheckedIn] = useState(false);
    const [voucherCounts, setVoucherCounts] = useState<VoucherCountNewOldValue>({
        oldValue: undefined,
        newValue: undefined,
    });

    const { user } = useAuth();
    const [vouchersIntroduced, setVouchersIntroduced] = useLocalStorage<boolean>('vouchers-introduced', false, {
        sync: false,
    });

    const { chaptersVoucherCounts, isLoading: isLoadingMyVouchers } = useMyVouchers();

    const getUserStreaksRequest = useGrpcRequest(GetUserStreaksRequest, {
        streakType: StreakType.CheckInStreak,
    });

    const { data: userStreaks, isLoading: isLoadingStreaks } = useGrpcApiWithQuery(
        UserStreaksClient,
        c => c.getUserStreaks,
        getUserStreaksRequest,
        ['user-streaks', user?.id],
        {
            enabled: user !== null && vouchersIntroduced && !isLoadingMyVouchers,
            cacheTime: 0, //Don't cache
            staleTime: 0, //Data is always stale
            refetchOnWindowFocus: true,
            refetchOnMount: false,
            keepPreviousData: true,
        }
    );

    const checkInStreakItem = useMemo<UserStreakItem | undefined>(() => {
        const streak = userStreaks?.items.find(s => s.streakType === StreakType.CheckInStreak);
        return streak;

        /*
        //Test
        const userStreakItem: UserStreakItem = {
            streakType: StreakType.CheckInStreak,
            streakState: UserStreakItem_State.Active,
            streakCount: 54,
            currentLevel: {
                levelNumber: 2,
                levelTitle: 'Engrossed reader',
                streakType: StreakType.CheckInStreak,
                range: {
                    baseRewardCount: 3,
                    minStreakCount: 29,
                    maxStreakCount: Int32Value.create({
                        value: 56,
                    }),
                },
            },
            bonusRewards: {
                4: {
                    reward: {
                        oneofKind: 'rewardCount',
                        rewardCount: Int32Value.create({
                            value: 2,
                        }),
                    },
                },
                7: {
                    reward: {
                        oneofKind: 'rewardCount',
                        rewardCount: Int32Value.create({
                            value: 3,
                        }),
                    },
                },
            },
        };

        return userStreakItem;*/
    }, [userStreaks]);

    const streakDays = useMemo(() => {
        return getStreakDays('All', checkInStreakItem);
    }, [checkInStreakItem]);

    const { executeRequest: completeCheckIn } = useCompleteMyLoginMission({
        onCompleted: data => {
            //if (data.item?.completed === true) {
            queryClient.invalidateQueries(['vouchers', user?.id]);
            queryClient.invalidateQueries(['user-streaks', user?.id]);
            queryClient.invalidateQueries(['voucher-history', user?.id]);
            queryClient.invalidateQueries(['missions-progress', user?.id]);
            setCheckedIn(true);
            //}
        },
    });

    useEffect(() => {
        if (
            !isLoadingMyVouchers &&
            !isLoadingStreaks &&
            !checkedIn &&
            userStreaks &&
            (userStreaks.items.length === 0 ||
                (checkInStreakItem && checkInStreakItem.streakState !== UserStreakItem_State.Active))
        ) {
            completeCheckIn();
        }
    }, [isLoadingMyVouchers, isLoadingStreaks, checkedIn, userStreaks, checkInStreakItem, completeCheckIn]);

    useEffect(() => {
        if (checkedIn && checkInStreakItem && !isLoadingMyVouchers && !isLoadingStreaks) {
            setStreakDialogOpen(true);
        }
    }, [checkedIn, checkInStreakItem, isLoadingMyVouchers, isLoadingStreaks]);

    useEffect(() => {
        setVoucherCounts(prev => {
            if (chaptersVoucherCounts === undefined) {
                return prev;
            }

            if (prev.oldValue !== undefined && prev.newValue !== undefined) {
                if (chaptersVoucherCounts <= prev.newValue) {
                    return prev;
                }

                prev.oldValue = prev.newValue;
                prev.newValue = chaptersVoucherCounts;
            }

            if (prev.oldValue === undefined) {
                prev.oldValue = chaptersVoucherCounts;
            } else if (prev.newValue === undefined && prev.oldValue < chaptersVoucherCounts) {
                prev.newValue = chaptersVoucherCounts;
            }

            return prev;
        });
    }, [chaptersVoucherCounts]);

    const closeStreaksDialog = useCallback(() => {
        setStreakDialogOpen(false);
    }, []);

    const closeVoucherIntroDialog = useCallback(() => {
        setVouchersIntroduced(true);
    }, [setVouchersIntroduced]);

    const getNextCheckInTime = () => {
        const randAdditionalMinutes = Math.floor(Math.random() * 5) + 1;

        return dayjs.utc().add(1, 'day').startOf('day').add(randAdditionalMinutes, 'minutes').toDate();
    };

    const [reload, setReload] = useState(false);
    const [nextRewardTime, setNextRewardTime] = useState(() => getNextCheckInTime());
    const nextRewardTimeRemaining = useTimeCountdown({
        futureDate: nextRewardTime,
        format: 'HH:mm',
        onTimeUp() {
            setNextRewardTime(getNextCheckInTime());
            setReload(true);
        },
    });

    const location = useLocation();

    useEffect(() => {
        if (!user || !reload) {
            return;
        }
        setReload(false);
        completeCheckIn();
    }, [location, completeCheckIn, user, reload, setReload]);

    const todayRewards = useMemo(() => {
        return streakDays?.find(d => d.isToday)?.rewardCount ?? 0;
    }, [streakDays]);

    return (
        <>
            <VouchersIntroDialog open={user !== null && !vouchersIntroduced} onClose={closeVoucherIntroDialog} />
            <Portal>
                <Dialog
                    open={streakDialogOpen}
                    onClose={closeStreaksDialog}
                    className="streaks-dialog"
                    PaperProps={{
                        className: 'streaks-dialog-paper',
                    }}
                    componentsProps={{ backdrop: { className: 'streaks-dialog-backdrop' } }}
                >
                    <DialogContent className="streaks-dialog-content">
                        <div className="streaks-title-container">
                            <p className="streaks-title">Daily rewards</p>
                            <p className="streaks-title-desc">
                                <span className="inline md:block">
                                    Sign in and visit us daily to earn a cultivation key!
                                </span>
                                <span className="inline md:block ml-[5px] md:ml-0">
                                    Next reward in{' '}
                                    <span className="text-blue-300">{nextRewardTimeRemaining} Hour(s)</span>
                                </span>
                            </p>
                            <div className="streaks-vouchers-collected">
                                <span>
                                    Total collected:{' '}
                                    <span>
                                        <span className="collected-vouchers-prev-value">
                                            {voucherCounts.oldValue ?? 0}
                                        </span>
                                        <span className="collected-vouchers-next-value">
                                            {voucherCounts.newValue ?? (voucherCounts.oldValue ?? 0) + todayRewards}
                                        </span>
                                    </span>
                                </span>
                                <CultivationKeyIcon className="w-[25px] h-[17px] sm:w-[30px] sm:h-[22px] inline drop-shadow-[0px_1px_5px_rgba(0,0,0,0.25)]" />
                            </div>
                        </div>
                        <div className="streaks-level-days-container">
                            <StreakLevelDetails checkInStreakItem={checkInStreakItem} />
                            <div className="streaks-days-list">
                                {streakDays?.map(
                                    ({
                                        dayInWeek,
                                        dayInStreak,
                                        isToday,
                                        isPending,
                                        rewardCount,
                                        bonusRewardNumber,
                                    }) => (
                                        <StreakDayItem
                                            key={dayInWeek}
                                            dayInWeek={dayInWeek}
                                            dayInStreak={dayInStreak}
                                            isToday={isToday}
                                            isPending={isPending}
                                            rewardCount={rewardCount}
                                            bonusRewardNumber={bonusRewardNumber}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                        <div className="streaks-action-container">
                            <Button
                                className="streaks-action-button"
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={closeStreaksDialog}
                            >
                                Continue Reading
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </Portal>
        </>
    );
}
