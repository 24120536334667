import type { UserStreakItem } from '@app/_proto/Protos/user_streaks';
import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';

//#region : Styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '&.MuiLinearProgress-root': {
        height: 8,
        borderRadius: '6.5px',
        '.MuiLinearProgress-bar': {
            borderRadius: '6.5px',
            backgroundColor: '#127AFE',
        },
    },
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: '#E8E8E8',
    },
}));
//#endregion : Styles

export type StreakLevelDetailsProps = {
    checkInStreakItem: UserStreakItem | undefined;
};

export default function StreakLevelDetails({ checkInStreakItem }: StreakLevelDetailsProps) {
    const range = checkInStreakItem?.currentLevel?.range;
    const totalDaysInStreak = range?.maxStreakCount?.value ? range.maxStreakCount.value - range.minStreakCount + 1 : 1;
    const todayInStreak = checkInStreakItem && range ? checkInStreakItem.streakCount - range.minStreakCount + 1 : 1;
    const progress = (todayInStreak * 100) / totalDaysInStreak;

    return (
        <div className="streak-level">
            <div className="streak-level-content">
                <div>
                    <img
                        src={`/images/user-streaks/level-${checkInStreakItem?.currentLevel?.levelNumber ?? 0}.png`}
                        alt=""
                        className="streak-level-img"
                    />
                </div>
                <div>
                    <div className="streak-level-title">{checkInStreakItem?.currentLevel?.levelTitle}</div>
                    <div className="streak-level-prompt">
                        Login daily to continue getting rewards and keep your streak!
                    </div>
                    {range?.maxStreakCount?.value && (
                        <div className="streak-level-progress-container">
                            <div>
                                <BorderLinearProgress variant="determinate" value={progress} />
                            </div>
                            <div className="streak-level-progress-text">
                                <div>Progress</div>
                                <div>
                                    {todayInStreak}/{totalDaysInStreak} Days
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
