// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/site_announcements.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Int32Value } from "../google/protobuf/wrappers";
import { UserItem } from "./users";
import { Timestamp } from "../google/protobuf/timestamp";
import { PageInfoResponse } from "./pagination";
import { PageInfoRequest } from "./pagination";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetSiteAnnouncementRequest
 */
export interface GetSiteAnnouncementRequest {
    /**
     * @generated from protobuf oneof: byProperty
     */
    byProperty: {
        oneofKind: "id";
        /**
         * @generated from protobuf field: int32 id = 1;
         */
        id: number;
    } | {
        oneofKind: "slug";
        /**
         * @generated from protobuf field: string slug = 2;
         */
        slug: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetSiteAnnouncementResponse
 */
export interface GetSiteAnnouncementResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.SiteAnnouncementItem item = 1;
     */
    item?: SiteAnnouncementItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetSiteAnnouncementsRequest
 */
export interface GetSiteAnnouncementsRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PageInfoRequest pageInfo = 1;
     */
    pageInfo?: PageInfoRequest;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetSiteAnnouncementsResponse
 */
export interface GetSiteAnnouncementsResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.SiteAnnouncementItem items = 1;
     */
    items: SiteAnnouncementItem[];
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PageInfoResponse pageInfo = 2;
     */
    pageInfo?: PageInfoResponse;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SiteAnnouncementItem
 */
export interface SiteAnnouncementItem {
    /**
     * @generated from protobuf field: int32 entityId = 1;
     */
    entityId: number;
    /**
     * @generated from protobuf field: string content = 2;
     */
    content: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timePosted = 3;
     */
    timePosted?: Timestamp;
    /**
     * @generated from protobuf field: string title = 4;
     */
    title: string;
    /**
     * @generated from protobuf field: string slug = 5;
     */
    slug: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserItem announcer = 6;
     */
    announcer?: UserItem;
    /**
     * @generated from protobuf field: bool isSticky = 7;
     */
    isSticky: boolean;
    /**
     * @generated from protobuf field: bool isImportant = 8;
     */
    isImportant: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value totalComments = 9;
     */
    totalComments?: Int32Value;
    /**
     * @generated from protobuf field: bool allowComments = 10;
     */
    allowComments: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetSiteAnnouncementRequest$Type extends MessageType<GetSiteAnnouncementRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetSiteAnnouncementRequest", [
            { no: 1, name: "id", kind: "scalar", oneof: "byProperty", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "slug", kind: "scalar", oneof: "byProperty", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetSiteAnnouncementRequest
 */
export const GetSiteAnnouncementRequest = new GetSiteAnnouncementRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSiteAnnouncementResponse$Type extends MessageType<GetSiteAnnouncementResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetSiteAnnouncementResponse", [
            { no: 1, name: "item", kind: "message", T: () => SiteAnnouncementItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetSiteAnnouncementResponse
 */
export const GetSiteAnnouncementResponse = new GetSiteAnnouncementResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSiteAnnouncementsRequest$Type extends MessageType<GetSiteAnnouncementsRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetSiteAnnouncementsRequest", [
            { no: 1, name: "pageInfo", kind: "message", T: () => PageInfoRequest }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetSiteAnnouncementsRequest
 */
export const GetSiteAnnouncementsRequest = new GetSiteAnnouncementsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSiteAnnouncementsResponse$Type extends MessageType<GetSiteAnnouncementsResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetSiteAnnouncementsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SiteAnnouncementItem },
            { no: 2, name: "pageInfo", kind: "message", T: () => PageInfoResponse }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetSiteAnnouncementsResponse
 */
export const GetSiteAnnouncementsResponse = new GetSiteAnnouncementsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SiteAnnouncementItem$Type extends MessageType<SiteAnnouncementItem> {
    constructor() {
        super("wuxiaworld.api.v2.SiteAnnouncementItem", [
            { no: 1, name: "entityId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timePosted", kind: "message", T: () => Timestamp },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "announcer", kind: "message", T: () => UserItem },
            { no: 7, name: "isSticky", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "isImportant", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "totalComments", kind: "message", T: () => Int32Value },
            { no: 10, name: "allowComments", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SiteAnnouncementItem
 */
export const SiteAnnouncementItem = new SiteAnnouncementItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.SiteAnnouncements
 */
export const SiteAnnouncements = new ServiceType("wuxiaworld.api.v2.SiteAnnouncements", [
    { name: "GetSiteAnnouncement", options: {}, I: GetSiteAnnouncementRequest, O: GetSiteAnnouncementResponse },
    { name: "GetSiteAnnouncements", options: {}, I: GetSiteAnnouncementsRequest, O: GetSiteAnnouncementsResponse }
]);
