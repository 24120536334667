import { Button } from '@mui/material';
import * as React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

//#region : Main component
/**
 * Custom helper component.
 *
 * Any component provided behaves like a link. Defaults to Button.
 */
export default function ButtonLink(props) {
    //#region : Variables, functions and api calls

    const { to, component, ...rest } = props;

    const navigate = useNavigate();

    //Navigate to the url provided on button click
    const handleOnClick = useCallback(() => {
        navigate(to);
    }, [navigate, to]);

    //If no component is provided default to Button
    const ButtonComponent = component || Button;

    //#endregion : Variables, functions and api calls

    return <ButtonComponent {...rest} onClick={handleOnClick} />;
}
//#endregion : Main component
