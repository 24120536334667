// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/karma.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Karma } from "./karma";
import type { GetMyKarmaTransactionsResponse } from "./karma";
import type { GetMyKarmaTransactionsRequest } from "./karma";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetMyKarmaTotalsResponse } from "./karma";
import type { GetMyKarmaTotalsRequest } from "./karma";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service wuxiaworld.api.v2.Karma
 */
export interface IKarmaClient {
    /**
     * @generated from protobuf rpc: GetMyKarmaTotals(wuxiaworld.api.v2.GetMyKarmaTotalsRequest) returns (wuxiaworld.api.v2.GetMyKarmaTotalsResponse);
     */
    getMyKarmaTotals(input: GetMyKarmaTotalsRequest, options?: RpcOptions): UnaryCall<GetMyKarmaTotalsRequest, GetMyKarmaTotalsResponse>;
    /**
     * @generated from protobuf rpc: GetMyKarmaTransactions(wuxiaworld.api.v2.GetMyKarmaTransactionsRequest) returns (wuxiaworld.api.v2.GetMyKarmaTransactionsResponse);
     */
    getMyKarmaTransactions(input: GetMyKarmaTransactionsRequest, options?: RpcOptions): UnaryCall<GetMyKarmaTransactionsRequest, GetMyKarmaTransactionsResponse>;
}
/**
 * @generated from protobuf service wuxiaworld.api.v2.Karma
 */
export class KarmaClient implements IKarmaClient, ServiceInfo {
    typeName = Karma.typeName;
    methods = Karma.methods;
    options = Karma.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetMyKarmaTotals(wuxiaworld.api.v2.GetMyKarmaTotalsRequest) returns (wuxiaworld.api.v2.GetMyKarmaTotalsResponse);
     */
    getMyKarmaTotals(input: GetMyKarmaTotalsRequest, options?: RpcOptions): UnaryCall<GetMyKarmaTotalsRequest, GetMyKarmaTotalsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMyKarmaTotalsRequest, GetMyKarmaTotalsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMyKarmaTransactions(wuxiaworld.api.v2.GetMyKarmaTransactionsRequest) returns (wuxiaworld.api.v2.GetMyKarmaTransactionsResponse);
     */
    getMyKarmaTransactions(input: GetMyKarmaTransactionsRequest, options?: RpcOptions): UnaryCall<GetMyKarmaTransactionsRequest, GetMyKarmaTransactionsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMyKarmaTransactionsRequest, GetMyKarmaTransactionsResponse>("unary", this._transport, method, opt, input);
    }
}
