// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/site_announcements.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { SiteAnnouncements } from "./site_announcements";
import type { GetSiteAnnouncementsResponse } from "./site_announcements";
import type { GetSiteAnnouncementsRequest } from "./site_announcements";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetSiteAnnouncementResponse } from "./site_announcements";
import type { GetSiteAnnouncementRequest } from "./site_announcements";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service wuxiaworld.api.v2.SiteAnnouncements
 */
export interface ISiteAnnouncementsClient {
    /**
     * @generated from protobuf rpc: GetSiteAnnouncement(wuxiaworld.api.v2.GetSiteAnnouncementRequest) returns (wuxiaworld.api.v2.GetSiteAnnouncementResponse);
     */
    getSiteAnnouncement(input: GetSiteAnnouncementRequest, options?: RpcOptions): UnaryCall<GetSiteAnnouncementRequest, GetSiteAnnouncementResponse>;
    /**
     * @generated from protobuf rpc: GetSiteAnnouncements(wuxiaworld.api.v2.GetSiteAnnouncementsRequest) returns (wuxiaworld.api.v2.GetSiteAnnouncementsResponse);
     */
    getSiteAnnouncements(input: GetSiteAnnouncementsRequest, options?: RpcOptions): UnaryCall<GetSiteAnnouncementsRequest, GetSiteAnnouncementsResponse>;
}
/**
 * @generated from protobuf service wuxiaworld.api.v2.SiteAnnouncements
 */
export class SiteAnnouncementsClient implements ISiteAnnouncementsClient, ServiceInfo {
    typeName = SiteAnnouncements.typeName;
    methods = SiteAnnouncements.methods;
    options = SiteAnnouncements.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetSiteAnnouncement(wuxiaworld.api.v2.GetSiteAnnouncementRequest) returns (wuxiaworld.api.v2.GetSiteAnnouncementResponse);
     */
    getSiteAnnouncement(input: GetSiteAnnouncementRequest, options?: RpcOptions): UnaryCall<GetSiteAnnouncementRequest, GetSiteAnnouncementResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSiteAnnouncementRequest, GetSiteAnnouncementResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSiteAnnouncements(wuxiaworld.api.v2.GetSiteAnnouncementsRequest) returns (wuxiaworld.api.v2.GetSiteAnnouncementsResponse);
     */
    getSiteAnnouncements(input: GetSiteAnnouncementsRequest, options?: RpcOptions): UnaryCall<GetSiteAnnouncementsRequest, GetSiteAnnouncementsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSiteAnnouncementsRequest, GetSiteAnnouncementsResponse>("unary", this._transport, method, opt, input);
    }
}
