// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/genres.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetGenresRequest
 */
export interface GetGenresRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.GenreLevel level = 1;
     */
    level: GenreLevel;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.GenreContent content = 2;
     */
    content: GenreContent[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetGenresResponse
 */
export interface GetGenresResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.GenreItem items = 1;
     */
    items: GenreItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GenreItem
 */
export interface GenreItem {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.GenreLevel level = 3;
     */
    level: GenreLevel;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.GenreContent content = 4;
     */
    content: GenreContent[];
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.GenreLevel
 */
export enum GenreLevel {
    /**
     * @generated from protobuf enum value: MainGenreLevel = 0;
     */
    MainGenreLevel = 0,
    /**
     * @generated from protobuf enum value: SubGenreLevel = 1;
     */
    SubGenreLevel = 1,
    /**
     * @generated from protobuf enum value: AllGenreLevels = -1;
     */
    AllGenreLevels = -1
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.GenreContent
 */
export enum GenreContent {
    /**
     * @generated from protobuf enum value: NotSpecified = 0;
     */
    NotSpecified = 0,
    /**
     * @generated from protobuf enum value: NovelGenreContent = 1;
     */
    NovelGenreContent = 1,
    /**
     * @generated from protobuf enum value: ComicGenreContent = 2;
     */
    ComicGenreContent = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class GetGenresRequest$Type extends MessageType<GetGenresRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetGenresRequest", [
            { no: 1, name: "level", kind: "enum", T: () => ["wuxiaworld.api.v2.GenreLevel", GenreLevel] },
            { no: 2, name: "content", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["wuxiaworld.api.v2.GenreContent", GenreContent] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetGenresRequest
 */
export const GetGenresRequest = new GetGenresRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGenresResponse$Type extends MessageType<GetGenresResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetGenresResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GenreItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetGenresResponse
 */
export const GetGenresResponse = new GetGenresResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenreItem$Type extends MessageType<GenreItem> {
    constructor() {
        super("wuxiaworld.api.v2.GenreItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "level", kind: "enum", T: () => ["wuxiaworld.api.v2.GenreLevel", GenreLevel] },
            { no: 4, name: "content", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["wuxiaworld.api.v2.GenreContent", GenreContent] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GenreItem
 */
export const GenreItem = new GenreItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Genres
 */
export const Genres = new ServiceType("wuxiaworld.api.v2.Genres", [
    { name: "GetGenres", options: {}, I: GetGenresRequest, O: GetGenresResponse }
]);
