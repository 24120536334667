import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

interface UseTimeCountdownOptions {
  futureDate: Date | undefined;
  format?: string;
  delay?: number;
  onTimeUp?: () => void;
}

const getTimeRemaining = (futureDate: Date | undefined, format: string) => {
  if (!futureDate) {
    return undefined;
  }

  const now = dayjs();

  if (now.isAfter(futureDate)) {
    return dayjs.duration(0).format(format);
  }

  return dayjs.duration(dayjs(futureDate).diff(now)).format(format);
};

const useTimeCountdown = (options: UseTimeCountdownOptions) => {
  const { futureDate, format = 'HH:mm', delay = 1000, onTimeUp } = options;

  const [timeRemaining, setTimeRemaining] = useState<string | undefined>(() => getTimeRemaining(futureDate, format));

  useEffect(() => {
    if (futureDate) {
      const interval = setInterval(() => {
        setTimeRemaining(getTimeRemaining(futureDate, format));

        const now = dayjs();

        if (now.isAfter(futureDate)) {
          onTimeUp?.();
          clearInterval(interval);
        }
      }, delay);

      return () => clearInterval(interval);
    }
  }, [futureDate, format, delay, onTimeUp]);

  return timeRemaining;
};

export default useTimeCountdown;
