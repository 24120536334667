// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/audio.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterAudioInfoRequest
 */
export interface GetChapterAudioInfoRequest {
    /**
     * @generated from protobuf field: int32 chapterId = 1;
     */
    chapterId: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterAudioInfoResponse
 */
export interface GetChapterAudioInfoResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterAudioInfo info = 1;
     */
    info?: ChapterAudioInfo;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterAudioRequest
 */
export interface GetChapterAudioRequest {
    /**
     * @generated from protobuf field: int32 chapterId = 1;
     */
    chapterId: number;
    /**
     * @generated from protobuf field: string voice = 2;
     */
    voice: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.AudioSpeed speed = 3;
     */
    speed: AudioSpeed;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.AudioFormat format = 4;
     */
    format: AudioFormat;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterAudioResponse
 */
export interface GetChapterAudioResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterAudio audio = 1;
     */
    audio?: ChapterAudio;
    /**
     * @generated from protobuf field: string audioFileUrl = 2;
     */
    audioFileUrl: string;
    /**
     * @generated from protobuf field: bool isPreview = 3;
     */
    isPreview: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterAudio
 */
export interface ChapterAudio {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ChapterAudioPart parts = 2;
     */
    parts: ChapterAudioPart[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterAudioPart
 */
export interface ChapterAudioPart {
    /**
     * @generated from protobuf field: int32 index = 1;
     */
    index: number;
    /**
     * @generated from protobuf field: int64 duration = 2;
     */
    duration: number;
    /**
     * @generated from protobuf field: string voice = 3;
     */
    voice: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.AudioSpeed speed = 4;
     */
    speed: AudioSpeed;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterAudioInfo
 */
export interface ChapterAudioInfo {
    /**
     * @generated from protobuf field: bool isAudioAvailable = 1;
     */
    isAudioAvailable: boolean;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ChapterAudioInfo.ChapterAudioPartInfo partInfos = 2;
     */
    partInfos: ChapterAudioInfo_ChapterAudioPartInfo[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterAudioInfo.ChapterAudioPartInfo
 */
export interface ChapterAudioInfo_ChapterAudioPartInfo {
    /**
     * @generated from protobuf field: string voice = 1;
     */
    voice: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.AudioSpeed speed = 2;
     */
    speed: AudioSpeed;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.AudioFormat
 */
export enum AudioFormat {
    /**
     * @generated from protobuf enum value: Mp3 = 0;
     */
    Mp3 = 0,
    /**
     * @generated from protobuf enum value: Opus = 1;
     */
    Opus = 1,
    /**
     * @generated from protobuf enum value: Caf = 2;
     */
    Caf = 2
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.AudioSpeed
 */
export enum AudioSpeed {
    /**
     * @generated from protobuf enum value: Normal = 0;
     */
    Normal = 0,
    /**
     * @generated from protobuf enum value: Fast = 1;
     */
    Fast = 1,
    /**
     * @generated from protobuf enum value: Slow = 2;
     */
    Slow = 2,
    /**
     * @generated from protobuf enum value: VerySlow = 3;
     */
    VerySlow = 3,
    /**
     * @generated from protobuf enum value: VeryFast = 4;
     */
    VeryFast = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterAudioInfoRequest$Type extends MessageType<GetChapterAudioInfoRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterAudioInfoRequest", [
            { no: 1, name: "chapterId", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterAudioInfoRequest
 */
export const GetChapterAudioInfoRequest = new GetChapterAudioInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterAudioInfoResponse$Type extends MessageType<GetChapterAudioInfoResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterAudioInfoResponse", [
            { no: 1, name: "info", kind: "message", T: () => ChapterAudioInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterAudioInfoResponse
 */
export const GetChapterAudioInfoResponse = new GetChapterAudioInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterAudioRequest$Type extends MessageType<GetChapterAudioRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterAudioRequest", [
            { no: 1, name: "chapterId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "voice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "speed", kind: "enum", T: () => ["wuxiaworld.api.v2.AudioSpeed", AudioSpeed] },
            { no: 4, name: "format", kind: "enum", T: () => ["wuxiaworld.api.v2.AudioFormat", AudioFormat] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterAudioRequest
 */
export const GetChapterAudioRequest = new GetChapterAudioRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterAudioResponse$Type extends MessageType<GetChapterAudioResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterAudioResponse", [
            { no: 1, name: "audio", kind: "message", T: () => ChapterAudio },
            { no: 2, name: "audioFileUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isPreview", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterAudioResponse
 */
export const GetChapterAudioResponse = new GetChapterAudioResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterAudio$Type extends MessageType<ChapterAudio> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterAudio", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "parts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChapterAudioPart }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterAudio
 */
export const ChapterAudio = new ChapterAudio$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterAudioPart$Type extends MessageType<ChapterAudioPart> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterAudioPart", [
            { no: 1, name: "index", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "duration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "voice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "speed", kind: "enum", T: () => ["wuxiaworld.api.v2.AudioSpeed", AudioSpeed] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterAudioPart
 */
export const ChapterAudioPart = new ChapterAudioPart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterAudioInfo$Type extends MessageType<ChapterAudioInfo> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterAudioInfo", [
            { no: 1, name: "isAudioAvailable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "partInfos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChapterAudioInfo_ChapterAudioPartInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterAudioInfo
 */
export const ChapterAudioInfo = new ChapterAudioInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterAudioInfo_ChapterAudioPartInfo$Type extends MessageType<ChapterAudioInfo_ChapterAudioPartInfo> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterAudioInfo.ChapterAudioPartInfo", [
            { no: 1, name: "voice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "speed", kind: "enum", T: () => ["wuxiaworld.api.v2.AudioSpeed", AudioSpeed] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterAudioInfo.ChapterAudioPartInfo
 */
export const ChapterAudioInfo_ChapterAudioPartInfo = new ChapterAudioInfo_ChapterAudioPartInfo$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Audio
 */
export const Audio = new ServiceType("wuxiaworld.api.v2.Audio", [
    { name: "GetChapterAudioInfo", options: {}, I: GetChapterAudioInfoRequest, O: GetChapterAudioInfoResponse },
    { name: "GetChapterAudio", options: {}, I: GetChapterAudioRequest, O: GetChapterAudioResponse }
]);
