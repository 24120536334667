// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/billing.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { Empty } from "../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
import { StringValue } from "../google/protobuf/wrappers";
import { PaymentMethodGateway } from "./payments";
import { PaymentMethodItem } from "./payments";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetPaymentMethodsResponse
 */
export interface GetPaymentMethodsResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.PaymentMethodItem items = 1;
     */
    items: PaymentMethodItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetPaymentMethodRequest
 */
export interface GetPaymentMethodRequest {
    /**
     * @generated from protobuf field: string paymentMethodId = 1;
     */
    paymentMethodId: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PaymentMethodGateway paymentGateway = 2;
     */
    paymentGateway: PaymentMethodGateway;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetPaymentMethodResponse
 */
export interface GetPaymentMethodResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PaymentMethodItem item = 1;
     */
    item?: PaymentMethodItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.AddPaymentMethodRequest
 */
export interface AddPaymentMethodRequest {
    /**
     * @generated from protobuf oneof: paymentMethod
     */
    paymentMethod: {
        oneofKind: "stripe";
        /**
         * @generated from protobuf field: wuxiaworld.api.v2.AddPaymentMethodRequest.AddStripePaymentMethod stripe = 1;
         */
        stripe: AddPaymentMethodRequest_AddStripePaymentMethod;
    } | {
        oneofKind: "braintree";
        /**
         * @generated from protobuf field: wuxiaworld.api.v2.AddPaymentMethodRequest.AddBraintreePaymentMethod braintree = 2;
         */
        braintree: AddPaymentMethodRequest_AddBraintreePaymentMethod;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.AddPaymentMethodRequest.AddStripePaymentMethod
 */
export interface AddPaymentMethodRequest_AddStripePaymentMethod {
    /**
     * @generated from protobuf field: google.protobuf.StringValue idempotencyKey = 1;
     */
    idempotencyKey?: StringValue;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.AddPaymentMethodRequest.AddBraintreePaymentMethod
 */
export interface AddPaymentMethodRequest_AddBraintreePaymentMethod {
    /**
     * @generated from protobuf field: google.protobuf.StringValue nonce = 1;
     */
    nonce?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue deviceData = 2;
     */
    deviceData?: StringValue;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.AddPaymentMethodResponse
 */
export interface AddPaymentMethodResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
    /**
     * @generated from protobuf oneof: gatewayResponse
     */
    gatewayResponse: {
        oneofKind: "stripe";
        /**
         * @generated from protobuf field: wuxiaworld.api.v2.AddPaymentMethodResponse.StripeGatewayResponse stripe = 2;
         */
        stripe: AddPaymentMethodResponse_StripeGatewayResponse;
    } | {
        oneofKind: "braintree";
        /**
         * @generated from protobuf field: wuxiaworld.api.v2.AddPaymentMethodResponse.BraintreeGatewayResponse braintree = 3;
         */
        braintree: AddPaymentMethodResponse_BraintreeGatewayResponse;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PaymentMethodItem paymentMethodItem = 4;
     */
    paymentMethodItem?: PaymentMethodItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.AddPaymentMethodResponse.StripeGatewayResponse
 */
export interface AddPaymentMethodResponse_StripeGatewayResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.AddPaymentMethodResponse.BraintreeGatewayResponse
 */
export interface AddPaymentMethodResponse_BraintreeGatewayResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.DeletePaymentMethodRequest
 */
export interface DeletePaymentMethodRequest {
    /**
     * @generated from protobuf field: string paymentMethodId = 1;
     */
    paymentMethodId: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PaymentMethodGateway paymentGateway = 2;
     */
    paymentGateway: PaymentMethodGateway;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.DeletePaymentMethodResponse
 */
export interface DeletePaymentMethodResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SetDefaultPaymentMethodRequest
 */
export interface SetDefaultPaymentMethodRequest {
    /**
     * @generated from protobuf field: string paymentMethodId = 1;
     */
    paymentMethodId: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PaymentMethodGateway paymentGateway = 2;
     */
    paymentGateway: PaymentMethodGateway;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SetDefaultPaymentMethodResponse
 */
export interface SetDefaultPaymentMethodResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetGatewayTokenRequest
 */
export interface GetGatewayTokenRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PaymentMethodGateway gateway = 1;
     */
    gateway: PaymentMethodGateway;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.GetGatewayTokenRequest.GatewayTokenType tokenType = 2;
     */
    tokenType: GetGatewayTokenRequest_GatewayTokenType;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.GetGatewayTokenRequest.GatewayTokenType
 */
export enum GetGatewayTokenRequest_GatewayTokenType {
    /**
     * @generated from protobuf enum value: Default = 0;
     */
    Default = 0,
    /**
     * @generated from protobuf enum value: AddPaymentMethod = 1;
     */
    AddPaymentMethod = 1,
    /**
     * @generated from protobuf enum value: Checkout = 2;
     */
    Checkout = 2
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetGatewayTokenResponse
 */
export interface GetGatewayTokenResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
    /**
     * @generated from protobuf field: google.protobuf.StringValue token = 2;
     */
    token?: StringValue;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.BillingPeriod
 */
export interface BillingPeriod {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp periodStart = 1;
     */
    periodStart?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp periodEnd = 2;
     */
    periodEnd?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentMethodsResponse$Type extends MessageType<GetPaymentMethodsResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetPaymentMethodsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PaymentMethodItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetPaymentMethodsResponse
 */
export const GetPaymentMethodsResponse = new GetPaymentMethodsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentMethodRequest$Type extends MessageType<GetPaymentMethodRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetPaymentMethodRequest", [
            { no: 1, name: "paymentMethodId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "paymentGateway", kind: "enum", T: () => ["wuxiaworld.api.v2.PaymentMethodGateway", PaymentMethodGateway] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetPaymentMethodRequest
 */
export const GetPaymentMethodRequest = new GetPaymentMethodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentMethodResponse$Type extends MessageType<GetPaymentMethodResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetPaymentMethodResponse", [
            { no: 1, name: "item", kind: "message", T: () => PaymentMethodItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetPaymentMethodResponse
 */
export const GetPaymentMethodResponse = new GetPaymentMethodResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPaymentMethodRequest$Type extends MessageType<AddPaymentMethodRequest> {
    constructor() {
        super("wuxiaworld.api.v2.AddPaymentMethodRequest", [
            { no: 1, name: "stripe", kind: "message", oneof: "paymentMethod", T: () => AddPaymentMethodRequest_AddStripePaymentMethod },
            { no: 2, name: "braintree", kind: "message", oneof: "paymentMethod", T: () => AddPaymentMethodRequest_AddBraintreePaymentMethod }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.AddPaymentMethodRequest
 */
export const AddPaymentMethodRequest = new AddPaymentMethodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPaymentMethodRequest_AddStripePaymentMethod$Type extends MessageType<AddPaymentMethodRequest_AddStripePaymentMethod> {
    constructor() {
        super("wuxiaworld.api.v2.AddPaymentMethodRequest.AddStripePaymentMethod", [
            { no: 1, name: "idempotencyKey", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.AddPaymentMethodRequest.AddStripePaymentMethod
 */
export const AddPaymentMethodRequest_AddStripePaymentMethod = new AddPaymentMethodRequest_AddStripePaymentMethod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPaymentMethodRequest_AddBraintreePaymentMethod$Type extends MessageType<AddPaymentMethodRequest_AddBraintreePaymentMethod> {
    constructor() {
        super("wuxiaworld.api.v2.AddPaymentMethodRequest.AddBraintreePaymentMethod", [
            { no: 1, name: "nonce", kind: "message", T: () => StringValue },
            { no: 2, name: "deviceData", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.AddPaymentMethodRequest.AddBraintreePaymentMethod
 */
export const AddPaymentMethodRequest_AddBraintreePaymentMethod = new AddPaymentMethodRequest_AddBraintreePaymentMethod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPaymentMethodResponse$Type extends MessageType<AddPaymentMethodResponse> {
    constructor() {
        super("wuxiaworld.api.v2.AddPaymentMethodResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "stripe", kind: "message", oneof: "gatewayResponse", T: () => AddPaymentMethodResponse_StripeGatewayResponse },
            { no: 3, name: "braintree", kind: "message", oneof: "gatewayResponse", T: () => AddPaymentMethodResponse_BraintreeGatewayResponse },
            { no: 4, name: "paymentMethodItem", kind: "message", T: () => PaymentMethodItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.AddPaymentMethodResponse
 */
export const AddPaymentMethodResponse = new AddPaymentMethodResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPaymentMethodResponse_StripeGatewayResponse$Type extends MessageType<AddPaymentMethodResponse_StripeGatewayResponse> {
    constructor() {
        super("wuxiaworld.api.v2.AddPaymentMethodResponse.StripeGatewayResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.AddPaymentMethodResponse.StripeGatewayResponse
 */
export const AddPaymentMethodResponse_StripeGatewayResponse = new AddPaymentMethodResponse_StripeGatewayResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPaymentMethodResponse_BraintreeGatewayResponse$Type extends MessageType<AddPaymentMethodResponse_BraintreeGatewayResponse> {
    constructor() {
        super("wuxiaworld.api.v2.AddPaymentMethodResponse.BraintreeGatewayResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.AddPaymentMethodResponse.BraintreeGatewayResponse
 */
export const AddPaymentMethodResponse_BraintreeGatewayResponse = new AddPaymentMethodResponse_BraintreeGatewayResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeletePaymentMethodRequest$Type extends MessageType<DeletePaymentMethodRequest> {
    constructor() {
        super("wuxiaworld.api.v2.DeletePaymentMethodRequest", [
            { no: 1, name: "paymentMethodId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "paymentGateway", kind: "enum", T: () => ["wuxiaworld.api.v2.PaymentMethodGateway", PaymentMethodGateway] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.DeletePaymentMethodRequest
 */
export const DeletePaymentMethodRequest = new DeletePaymentMethodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeletePaymentMethodResponse$Type extends MessageType<DeletePaymentMethodResponse> {
    constructor() {
        super("wuxiaworld.api.v2.DeletePaymentMethodResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.DeletePaymentMethodResponse
 */
export const DeletePaymentMethodResponse = new DeletePaymentMethodResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetDefaultPaymentMethodRequest$Type extends MessageType<SetDefaultPaymentMethodRequest> {
    constructor() {
        super("wuxiaworld.api.v2.SetDefaultPaymentMethodRequest", [
            { no: 1, name: "paymentMethodId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "paymentGateway", kind: "enum", T: () => ["wuxiaworld.api.v2.PaymentMethodGateway", PaymentMethodGateway] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SetDefaultPaymentMethodRequest
 */
export const SetDefaultPaymentMethodRequest = new SetDefaultPaymentMethodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetDefaultPaymentMethodResponse$Type extends MessageType<SetDefaultPaymentMethodResponse> {
    constructor() {
        super("wuxiaworld.api.v2.SetDefaultPaymentMethodResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SetDefaultPaymentMethodResponse
 */
export const SetDefaultPaymentMethodResponse = new SetDefaultPaymentMethodResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGatewayTokenRequest$Type extends MessageType<GetGatewayTokenRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetGatewayTokenRequest", [
            { no: 1, name: "gateway", kind: "enum", T: () => ["wuxiaworld.api.v2.PaymentMethodGateway", PaymentMethodGateway] },
            { no: 2, name: "tokenType", kind: "enum", T: () => ["wuxiaworld.api.v2.GetGatewayTokenRequest.GatewayTokenType", GetGatewayTokenRequest_GatewayTokenType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetGatewayTokenRequest
 */
export const GetGatewayTokenRequest = new GetGatewayTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGatewayTokenResponse$Type extends MessageType<GetGatewayTokenResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetGatewayTokenResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "token", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetGatewayTokenResponse
 */
export const GetGatewayTokenResponse = new GetGatewayTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingPeriod$Type extends MessageType<BillingPeriod> {
    constructor() {
        super("wuxiaworld.api.v2.BillingPeriod", [
            { no: 1, name: "periodStart", kind: "message", T: () => Timestamp },
            { no: 2, name: "periodEnd", kind: "message", T: () => Timestamp }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.BillingPeriod
 */
export const BillingPeriod = new BillingPeriod$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Billing
 */
export const Billing = new ServiceType("wuxiaworld.api.v2.Billing", [
    { name: "GetPaymentMethods", options: {}, I: Empty, O: GetPaymentMethodsResponse },
    { name: "GetPaymentMethod", options: {}, I: GetPaymentMethodRequest, O: GetPaymentMethodResponse },
    { name: "AddPaymentMethod", options: {}, I: AddPaymentMethodRequest, O: AddPaymentMethodResponse },
    { name: "DeletePaymentMethod", options: {}, I: DeletePaymentMethodRequest, O: DeletePaymentMethodResponse },
    { name: "SetDefaultPaymentMethod", options: {}, I: SetDefaultPaymentMethodRequest, O: SetDefaultPaymentMethodResponse },
    { name: "GetGatewayToken", options: {}, I: GetGatewayTokenRequest, O: GetGatewayTokenResponse }
]);
