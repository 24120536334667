// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/user_streaks.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
import { Int32Value } from "../google/protobuf/wrappers";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetUserStreaksRequest
 */
export interface GetUserStreaksRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.StreakType streakType = 1;
     */
    streakType: StreakType;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetUserStreaksResponse
 */
export interface GetUserStreaksResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.UserStreakItem items = 1;
     */
    items: UserStreakItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetStreakLevelsRequest
 */
export interface GetStreakLevelsRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.StreakType streakType = 1;
     */
    streakType: StreakType;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetStreakLevelsResponse
 */
export interface GetStreakLevelsResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.StreakLevel levels = 1;
     */
    levels: StreakLevel[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.StreakLevel
 */
export interface StreakLevel {
    /**
     * @generated from protobuf field: int32 levelNumber = 1;
     */
    levelNumber: number;
    /**
     * @generated from protobuf field: string levelTitle = 2;
     */
    levelTitle: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.StreakLevelRange range = 3;
     */
    range?: StreakLevelRange;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.StreakType streakType = 4;
     */
    streakType: StreakType;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.BonusStreakReward
 */
export interface BonusStreakReward {
    /**
     * @generated from protobuf oneof: reward
     */
    reward: {
        oneofKind: "rewardCount";
        /**
         * @generated from protobuf field: google.protobuf.Int32Value rewardCount = 2;
         */
        rewardCount: Int32Value;
    } | {
        oneofKind: "rewardMultiplier";
        /**
         * @generated from protobuf field: google.protobuf.Int32Value rewardMultiplier = 3;
         */
        rewardMultiplier: Int32Value;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.StreakLevelRange
 */
export interface StreakLevelRange {
    /**
     * @generated from protobuf field: int32 minStreakCount = 1;
     */
    minStreakCount: number;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value maxStreakCount = 2;
     */
    maxStreakCount?: Int32Value;
    /**
     * @generated from protobuf field: int32 baseRewardCount = 3;
     */
    baseRewardCount: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.UserStreakItem
 */
export interface UserStreakItem {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.StreakType streakType = 1;
     */
    streakType: StreakType;
    /**
     * @generated from protobuf field: int32 streakCount = 2;
     */
    streakCount: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp streakStartedAt = 3;
     */
    streakStartedAt?: Timestamp;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserStreakItem.State streakState = 4;
     */
    streakState: UserStreakItem_State;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.StreakLevel currentLevel = 5;
     */
    currentLevel?: StreakLevel;
    /**
     * @generated from protobuf field: map<int32, wuxiaworld.api.v2.BonusStreakReward> bonusRewards = 6;
     */
    bonusRewards: {
        [key: number]: BonusStreakReward;
    };
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.UserStreakItem.State
 */
export enum UserStreakItem_State {
    /**
     * @generated from protobuf enum value: Inactive = 0;
     */
    Inactive = 0,
    /**
     * @generated from protobuf enum value: Active = 1;
     */
    Active = 1,
    /**
     * @generated from protobuf enum value: Pending = 2;
     */
    Pending = 2
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.StreakType
 */
export enum StreakType {
    /**
     * @generated from protobuf enum value: UnknownStreak = 0;
     */
    UnknownStreak = 0,
    /**
     * @generated from protobuf enum value: CheckInStreak = 1;
     */
    CheckInStreak = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class GetUserStreaksRequest$Type extends MessageType<GetUserStreaksRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetUserStreaksRequest", [
            { no: 1, name: "streakType", kind: "enum", T: () => ["wuxiaworld.api.v2.StreakType", StreakType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetUserStreaksRequest
 */
export const GetUserStreaksRequest = new GetUserStreaksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserStreaksResponse$Type extends MessageType<GetUserStreaksResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetUserStreaksResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserStreakItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetUserStreaksResponse
 */
export const GetUserStreaksResponse = new GetUserStreaksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStreakLevelsRequest$Type extends MessageType<GetStreakLevelsRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetStreakLevelsRequest", [
            { no: 1, name: "streakType", kind: "enum", T: () => ["wuxiaworld.api.v2.StreakType", StreakType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetStreakLevelsRequest
 */
export const GetStreakLevelsRequest = new GetStreakLevelsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStreakLevelsResponse$Type extends MessageType<GetStreakLevelsResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetStreakLevelsResponse", [
            { no: 1, name: "levels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreakLevel }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetStreakLevelsResponse
 */
export const GetStreakLevelsResponse = new GetStreakLevelsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreakLevel$Type extends MessageType<StreakLevel> {
    constructor() {
        super("wuxiaworld.api.v2.StreakLevel", [
            { no: 1, name: "levelNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "levelTitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "range", kind: "message", T: () => StreakLevelRange },
            { no: 4, name: "streakType", kind: "enum", T: () => ["wuxiaworld.api.v2.StreakType", StreakType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.StreakLevel
 */
export const StreakLevel = new StreakLevel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusStreakReward$Type extends MessageType<BonusStreakReward> {
    constructor() {
        super("wuxiaworld.api.v2.BonusStreakReward", [
            { no: 2, name: "rewardCount", kind: "message", oneof: "reward", T: () => Int32Value },
            { no: 3, name: "rewardMultiplier", kind: "message", oneof: "reward", T: () => Int32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.BonusStreakReward
 */
export const BonusStreakReward = new BonusStreakReward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreakLevelRange$Type extends MessageType<StreakLevelRange> {
    constructor() {
        super("wuxiaworld.api.v2.StreakLevelRange", [
            { no: 1, name: "minStreakCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "maxStreakCount", kind: "message", T: () => Int32Value },
            { no: 3, name: "baseRewardCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.StreakLevelRange
 */
export const StreakLevelRange = new StreakLevelRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserStreakItem$Type extends MessageType<UserStreakItem> {
    constructor() {
        super("wuxiaworld.api.v2.UserStreakItem", [
            { no: 1, name: "streakType", kind: "enum", T: () => ["wuxiaworld.api.v2.StreakType", StreakType] },
            { no: 2, name: "streakCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "streakStartedAt", kind: "message", T: () => Timestamp },
            { no: 4, name: "streakState", kind: "enum", T: () => ["wuxiaworld.api.v2.UserStreakItem.State", UserStreakItem_State] },
            { no: 5, name: "currentLevel", kind: "message", T: () => StreakLevel },
            { no: 6, name: "bonusRewards", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "message", T: () => BonusStreakReward } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.UserStreakItem
 */
export const UserStreakItem = new UserStreakItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.UserStreaks
 */
export const UserStreaks = new ServiceType("wuxiaworld.api.v2.UserStreaks", [
    { name: "GetUserStreaks", options: {}, I: GetUserStreaksRequest, O: GetUserStreaksResponse },
    { name: "GetStreakLevels", options: {}, I: GetStreakLevelsRequest, O: GetStreakLevelsResponse }
]);
