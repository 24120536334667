// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/chapters.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { ChapterAudioInfo } from "./audio";
import { ChapterParagraph } from "./chapter_paragraphs";
import { UnlockItemMethod } from "./unlocks";
import { Timestamp } from "../google/protobuf/timestamp";
import { SeriesItem } from "./series";
import { StringValue } from "../google/protobuf/wrappers";
import { DecimalValue } from "./types";
import { PageInfoResponse } from "./pagination";
import { PageInfoRequest } from "./pagination";
import { BoolValue } from "../google/protobuf/wrappers";
import { Int32Value } from "../google/protobuf/wrappers";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterListRequest
 */
export interface GetChapterListRequest {
    /**
     * @generated from protobuf field: int32 novelId = 1;
     */
    novelId: number;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.GetChapterListRequest.FilterChapters filter = 2;
     */
    filter?: GetChapterListRequest_FilterChapters;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value count = 3;
     */
    count?: Int32Value;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterListRequest.BaseChapterInfo
 */
export interface GetChapterListRequest_BaseChapterInfo {
    /**
     * @generated from protobuf oneof: chapterInfo
     */
    chapterInfo: {
        oneofKind: "chapterId";
        /**
         * @generated from protobuf field: int32 chapterId = 1;
         */
        chapterId: number;
    } | {
        oneofKind: "slug";
        /**
         * @generated from protobuf field: string slug = 2;
         */
        slug: string;
    } | {
        oneofKind: "offset";
        /**
         * @generated from protobuf field: int32 offset = 3;
         */
        offset: number;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterListRequest.FilterChapters
 */
export interface GetChapterListRequest_FilterChapters {
    /**
     * @generated from protobuf field: google.protobuf.Int32Value chapterGroupId = 1;
     */
    chapterGroupId?: Int32Value;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isAdvanceChapter = 2;
     */
    isAdvanceChapter?: BoolValue;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.GetChapterListRequest.BaseChapterInfo baseChapter = 3;
     */
    baseChapter?: GetChapterListRequest_BaseChapterInfo;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterByProperty
 */
export interface GetChapterByProperty {
    /**
     * @generated from protobuf oneof: byProperty
     */
    byProperty: {
        oneofKind: "chapterId";
        /**
         * @generated from protobuf field: int32 chapterId = 1;
         */
        chapterId: number;
    } | {
        oneofKind: "slugs";
        /**
         * @generated from protobuf field: wuxiaworld.api.v2.GetChapterByProperty.ByNovelAndChapterSlug slugs = 2;
         */
        slugs: GetChapterByProperty_ByNovelAndChapterSlug;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterByProperty.ByNovelAndChapterSlug
 */
export interface GetChapterByProperty_ByNovelAndChapterSlug {
    /**
     * @generated from protobuf field: string novelSlug = 1;
     */
    novelSlug: string;
    /**
     * @generated from protobuf field: string chapterSlug = 2;
     */
    chapterSlug: string;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterRequest
 */
export interface GetChapterRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.GetChapterByProperty chapterProperty = 1;
     */
    chapterProperty?: GetChapterByProperty;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChaptersRequest
 */
export interface GetChaptersRequest {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.GetChapterByProperty chapterProperties = 1;
     */
    chapterProperties: GetChapterByProperty[];
    /**
     * @generated from protobuf field: int32 batchSize = 2;
     */
    batchSize: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChaptersBatch
 */
export interface GetChaptersBatch {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ChapterItem items = 1;
     */
    items: ChapterItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterListResponse
 */
export interface GetChapterListResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ChapterGroupItem items = 1;
     */
    items: ChapterGroupItem[];
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterNovelInfo novelInfo = 2;
     */
    novelInfo?: ChapterNovelInfo;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetChapterResponse
 */
export interface GetChapterResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterItem item = 1;
     */
    item?: ChapterItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetLatestChaptersRequest
 */
export interface GetLatestChaptersRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PageInfoRequest pageInfo = 1;
     */
    pageInfo?: PageInfoRequest;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value novelId = 2;
     */
    novelId?: Int32Value;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetLatestChaptersResponse
 */
export interface GetLatestChaptersResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PageInfoResponse pageInfo = 1;
     */
    pageInfo?: PageInfoResponse;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ChapterItem items = 2;
     */
    items: ChapterItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterGroupCounts
 */
export interface ChapterGroupCounts {
    /**
     * @generated from protobuf field: int32 total = 1;
     */
    total: number;
    /**
     * @generated from protobuf field: int32 advance = 2;
     */
    advance: number;
    /**
     * @generated from protobuf field: int32 normal = 3;
     */
    normal: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterGroupItem
 */
export interface ChapterGroupItem {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: int32 order = 3;
     */
    order: number;
    /**
     * @generated from protobuf field: customTypes.DecimalValue fromChapterNumber = 4;
     */
    fromChapterNumber?: DecimalValue;
    /**
     * @generated from protobuf field: customTypes.DecimalValue toChapterNumber = 5;
     */
    toChapterNumber?: DecimalValue;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ChapterItem chapterList = 6;
     */
    chapterList: ChapterItem[];
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterGroupCounts counts = 7;
     */
    counts?: ChapterGroupCounts;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.RelatedChapterInfo
 */
export interface RelatedChapterInfo {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterItem previousChapter = 1;
     */
    previousChapter?: ChapterItem;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterItem nextChapter = 2;
     */
    nextChapter?: ChapterItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterNovelInfo
 */
export interface ChapterNovelInfo {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue coverUrl = 3;
     */
    coverUrl?: StringValue;
    /**
     * @generated from protobuf field: string slug = 4;
     */
    slug: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterNovelInfo.KarmaInfo karmaInfo = 5;
     */
    karmaInfo?: ChapterNovelInfo_KarmaInfo;
    /**
     * @generated from protobuf field: bool isSneakPeek = 6;
     */
    isSneakPeek: boolean;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterNovelTranslatorInfo translatorInfo = 7;
     */
    translatorInfo?: ChapterNovelTranslatorInfo;
    /**
     * @generated from protobuf field: google.protobuf.StringValue language = 8;
     */
    language?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue coverBlurHash = 9;
     */
    coverBlurHash?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue translatorName = 10;
     */
    translatorName?: StringValue;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.SeriesItem series = 11;
     */
    series?: SeriesItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterNovelInfo.KarmaInfo
 */
export interface ChapterNovelInfo_KarmaInfo {
    /**
     * @generated from protobuf field: bool isActive = 1;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: bool isFree = 2;
     */
    isFree: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterNovelTranslatorInfo
 */
export interface ChapterNovelTranslatorInfo {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo userInfo = 1;
     */
    userInfo?: ChapterNovelTranslatorInfo_UserInfo;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo
 */
export interface ChapterNovelTranslatorInfo_UserInfo {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string userName = 2;
     */
    userName: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue avatarUrl = 3;
     */
    avatarUrl?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp joinDate = 4;
     */
    joinDate?: Timestamp;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterKarma
 */
export interface ChapterKarma {
    /**
     * @generated from protobuf field: google.protobuf.Int32Value karmaPrice = 1;
     */
    karmaPrice?: Int32Value;
    /**
     * @generated from protobuf field: bool isKarmaRequired = 2;
     */
    isKarmaRequired: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterPricing
 */
export interface ChapterPricing {
    /**
     * @generated from protobuf field: bool isFree = 1;
     */
    isFree: boolean;
    /**
     * @generated from protobuf field: bool isLastHoldback = 2;
     */
    isLastHoldback: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterSponsor
 */
export interface ChapterSponsor {
    /**
     * @generated from protobuf field: bool advanceChapter = 1;
     */
    advanceChapter: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value advanceChapterNumber = 2;
     */
    advanceChapterNumber?: Int32Value;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ChapterSponsor.AdvanceChapterPlan plans = 3;
     */
    plans: ChapterSponsor_AdvanceChapterPlan[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterSponsor.AdvanceChapterPlan
 */
export interface ChapterSponsor_AdvanceChapterPlan {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 advanceChapterCount = 2;
     */
    advanceChapterCount: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.RelatedChapterUserInfo
 */
export interface RelatedChapterUserInfo {
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isChapterUnlocked = 1;
     */
    isChapterUnlocked?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isNovelUnlocked = 2;
     */
    isNovelUnlocked?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isChapterFavorite = 3;
     */
    isChapterFavorite?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isNovelOwned = 4;
     */
    isNovelOwned?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isChapterOwned = 5;
     */
    isChapterOwned?: BoolValue;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.RelatedChapterUserInfo.UserUnlockInfo unlockInfo = 6;
     */
    unlockInfo?: RelatedChapterUserInfo_UserUnlockInfo;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isAudioUnlocked = 7;
     */
    isAudioUnlocked?: BoolValue;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.RelatedChapterUserInfo.UserUnlockInfo
 */
export interface RelatedChapterUserInfo_UserUnlockInfo {
    /**
     * @generated from protobuf field: bool isUnlockedNow = 1;
     */
    isUnlockedNow: boolean;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UnlockItemMethod unlockNowMethod = 2;
     */
    unlockNowMethod: UnlockItemMethod;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ChapterItem
 */
export interface ChapterItem {
    /**
     * @generated from protobuf field: int32 entityId = 1;
     */
    entityId: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string slug = 3;
     */
    slug: string;
    /**
     * @generated from protobuf field: customTypes.DecimalValue number = 4;
     */
    number?: DecimalValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue content = 5;
     */
    content?: StringValue;
    /**
     * @generated from protobuf field: int32 novelId = 6;
     */
    novelId: number;
    /**
     * @generated from protobuf field: bool visible = 7;
     */
    visible: boolean;
    /**
     * @generated from protobuf field: bool isTeaser = 8;
     */
    isTeaser: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp whenToPublish = 9;
     */
    whenToPublish?: Timestamp;
    /**
     * @generated from protobuf field: bool spoilerTitle = 10;
     */
    spoilerTitle: boolean;
    /**
     * @generated from protobuf field: bool allowComments = 11;
     */
    allowComments: boolean;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.RelatedChapterInfo relatedChapterInfo = 12;
     */
    relatedChapterInfo?: RelatedChapterInfo;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterKarma karmaInfo = 13;
     */
    karmaInfo?: ChapterKarma;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterNovelInfo novelInfo = 14;
     */
    novelInfo?: ChapterNovelInfo;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterSponsor sponsorInfo = 15;
     */
    sponsorInfo?: ChapterSponsor;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.RelatedChapterUserInfo relatedUserInfo = 16;
     */
    relatedUserInfo?: RelatedChapterUserInfo;
    /**
     * @generated from protobuf field: int32 offset = 17;
     */
    offset: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp publishedAt = 18;
     */
    publishedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.StringValue translatorThoughts = 19;
     */
    translatorThoughts?: StringValue;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterPricing pricingInfo = 20;
     */
    pricingInfo?: ChapterPricing;
    /**
     * @generated from protobuf field: repeated ChapterParagraph paragraphs = 21;
     */
    paragraphs: ChapterParagraph[];
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ChapterAudioInfo audioInfo = 22;
     */
    audioInfo?: ChapterAudioInfo;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterListRequest$Type extends MessageType<GetChapterListRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterListRequest", [
            { no: 1, name: "novelId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "filter", kind: "message", T: () => GetChapterListRequest_FilterChapters },
            { no: 3, name: "count", kind: "message", T: () => Int32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterListRequest
 */
export const GetChapterListRequest = new GetChapterListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterListRequest_BaseChapterInfo$Type extends MessageType<GetChapterListRequest_BaseChapterInfo> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterListRequest.BaseChapterInfo", [
            { no: 1, name: "chapterId", kind: "scalar", oneof: "chapterInfo", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "slug", kind: "scalar", oneof: "chapterInfo", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", oneof: "chapterInfo", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterListRequest.BaseChapterInfo
 */
export const GetChapterListRequest_BaseChapterInfo = new GetChapterListRequest_BaseChapterInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterListRequest_FilterChapters$Type extends MessageType<GetChapterListRequest_FilterChapters> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterListRequest.FilterChapters", [
            { no: 1, name: "chapterGroupId", kind: "message", T: () => Int32Value },
            { no: 2, name: "isAdvanceChapter", kind: "message", T: () => BoolValue },
            { no: 3, name: "baseChapter", kind: "message", T: () => GetChapterListRequest_BaseChapterInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterListRequest.FilterChapters
 */
export const GetChapterListRequest_FilterChapters = new GetChapterListRequest_FilterChapters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterByProperty$Type extends MessageType<GetChapterByProperty> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterByProperty", [
            { no: 1, name: "chapterId", kind: "scalar", oneof: "byProperty", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "slugs", kind: "message", oneof: "byProperty", T: () => GetChapterByProperty_ByNovelAndChapterSlug }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterByProperty
 */
export const GetChapterByProperty = new GetChapterByProperty$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterByProperty_ByNovelAndChapterSlug$Type extends MessageType<GetChapterByProperty_ByNovelAndChapterSlug> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterByProperty.ByNovelAndChapterSlug", [
            { no: 1, name: "novelSlug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "chapterSlug", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterByProperty.ByNovelAndChapterSlug
 */
export const GetChapterByProperty_ByNovelAndChapterSlug = new GetChapterByProperty_ByNovelAndChapterSlug$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterRequest$Type extends MessageType<GetChapterRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterRequest", [
            { no: 1, name: "chapterProperty", kind: "message", T: () => GetChapterByProperty }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterRequest
 */
export const GetChapterRequest = new GetChapterRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChaptersRequest$Type extends MessageType<GetChaptersRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetChaptersRequest", [
            { no: 1, name: "chapterProperties", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetChapterByProperty },
            { no: 2, name: "batchSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChaptersRequest
 */
export const GetChaptersRequest = new GetChaptersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChaptersBatch$Type extends MessageType<GetChaptersBatch> {
    constructor() {
        super("wuxiaworld.api.v2.GetChaptersBatch", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChapterItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChaptersBatch
 */
export const GetChaptersBatch = new GetChaptersBatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterListResponse$Type extends MessageType<GetChapterListResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterListResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChapterGroupItem },
            { no: 2, name: "novelInfo", kind: "message", T: () => ChapterNovelInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterListResponse
 */
export const GetChapterListResponse = new GetChapterListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChapterResponse$Type extends MessageType<GetChapterResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetChapterResponse", [
            { no: 1, name: "item", kind: "message", T: () => ChapterItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetChapterResponse
 */
export const GetChapterResponse = new GetChapterResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLatestChaptersRequest$Type extends MessageType<GetLatestChaptersRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetLatestChaptersRequest", [
            { no: 1, name: "pageInfo", kind: "message", T: () => PageInfoRequest, options: { "wuxiaworld.api.v2.required": { value: true } } },
            { no: 2, name: "novelId", kind: "message", T: () => Int32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetLatestChaptersRequest
 */
export const GetLatestChaptersRequest = new GetLatestChaptersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLatestChaptersResponse$Type extends MessageType<GetLatestChaptersResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetLatestChaptersResponse", [
            { no: 1, name: "pageInfo", kind: "message", T: () => PageInfoResponse },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChapterItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetLatestChaptersResponse
 */
export const GetLatestChaptersResponse = new GetLatestChaptersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterGroupCounts$Type extends MessageType<ChapterGroupCounts> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterGroupCounts", [
            { no: 1, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "advance", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "normal", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterGroupCounts
 */
export const ChapterGroupCounts = new ChapterGroupCounts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterGroupItem$Type extends MessageType<ChapterGroupItem> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterGroupItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "fromChapterNumber", kind: "message", T: () => DecimalValue },
            { no: 5, name: "toChapterNumber", kind: "message", T: () => DecimalValue },
            { no: 6, name: "chapterList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChapterItem },
            { no: 7, name: "counts", kind: "message", T: () => ChapterGroupCounts }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterGroupItem
 */
export const ChapterGroupItem = new ChapterGroupItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelatedChapterInfo$Type extends MessageType<RelatedChapterInfo> {
    constructor() {
        super("wuxiaworld.api.v2.RelatedChapterInfo", [
            { no: 1, name: "previousChapter", kind: "message", T: () => ChapterItem },
            { no: 2, name: "nextChapter", kind: "message", T: () => ChapterItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.RelatedChapterInfo
 */
export const RelatedChapterInfo = new RelatedChapterInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterNovelInfo$Type extends MessageType<ChapterNovelInfo> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterNovelInfo", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "coverUrl", kind: "message", T: () => StringValue },
            { no: 4, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "karmaInfo", kind: "message", T: () => ChapterNovelInfo_KarmaInfo },
            { no: 6, name: "isSneakPeek", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "translatorInfo", kind: "message", T: () => ChapterNovelTranslatorInfo },
            { no: 8, name: "language", kind: "message", T: () => StringValue },
            { no: 9, name: "coverBlurHash", kind: "message", T: () => StringValue },
            { no: 10, name: "translatorName", kind: "message", T: () => StringValue },
            { no: 11, name: "series", kind: "message", T: () => SeriesItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterNovelInfo
 */
export const ChapterNovelInfo = new ChapterNovelInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterNovelInfo_KarmaInfo$Type extends MessageType<ChapterNovelInfo_KarmaInfo> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterNovelInfo.KarmaInfo", [
            { no: 1, name: "isActive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "isFree", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterNovelInfo.KarmaInfo
 */
export const ChapterNovelInfo_KarmaInfo = new ChapterNovelInfo_KarmaInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterNovelTranslatorInfo$Type extends MessageType<ChapterNovelTranslatorInfo> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterNovelTranslatorInfo", [
            { no: 1, name: "userInfo", kind: "message", T: () => ChapterNovelTranslatorInfo_UserInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterNovelTranslatorInfo
 */
export const ChapterNovelTranslatorInfo = new ChapterNovelTranslatorInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterNovelTranslatorInfo_UserInfo$Type extends MessageType<ChapterNovelTranslatorInfo_UserInfo> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "avatarUrl", kind: "message", T: () => StringValue },
            { no: 4, name: "joinDate", kind: "message", T: () => Timestamp }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo
 */
export const ChapterNovelTranslatorInfo_UserInfo = new ChapterNovelTranslatorInfo_UserInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterKarma$Type extends MessageType<ChapterKarma> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterKarma", [
            { no: 1, name: "karmaPrice", kind: "message", T: () => Int32Value },
            { no: 2, name: "isKarmaRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterKarma
 */
export const ChapterKarma = new ChapterKarma$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterPricing$Type extends MessageType<ChapterPricing> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterPricing", [
            { no: 1, name: "isFree", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "isLastHoldback", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterPricing
 */
export const ChapterPricing = new ChapterPricing$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterSponsor$Type extends MessageType<ChapterSponsor> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterSponsor", [
            { no: 1, name: "advanceChapter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "advanceChapterNumber", kind: "message", T: () => Int32Value },
            { no: 3, name: "plans", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChapterSponsor_AdvanceChapterPlan }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterSponsor
 */
export const ChapterSponsor = new ChapterSponsor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterSponsor_AdvanceChapterPlan$Type extends MessageType<ChapterSponsor_AdvanceChapterPlan> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterSponsor.AdvanceChapterPlan", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "advanceChapterCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterSponsor.AdvanceChapterPlan
 */
export const ChapterSponsor_AdvanceChapterPlan = new ChapterSponsor_AdvanceChapterPlan$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelatedChapterUserInfo$Type extends MessageType<RelatedChapterUserInfo> {
    constructor() {
        super("wuxiaworld.api.v2.RelatedChapterUserInfo", [
            { no: 1, name: "isChapterUnlocked", kind: "message", T: () => BoolValue },
            { no: 2, name: "isNovelUnlocked", kind: "message", T: () => BoolValue },
            { no: 3, name: "isChapterFavorite", kind: "message", T: () => BoolValue },
            { no: 4, name: "isNovelOwned", kind: "message", T: () => BoolValue },
            { no: 5, name: "isChapterOwned", kind: "message", T: () => BoolValue },
            { no: 6, name: "unlockInfo", kind: "message", T: () => RelatedChapterUserInfo_UserUnlockInfo },
            { no: 7, name: "isAudioUnlocked", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.RelatedChapterUserInfo
 */
export const RelatedChapterUserInfo = new RelatedChapterUserInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelatedChapterUserInfo_UserUnlockInfo$Type extends MessageType<RelatedChapterUserInfo_UserUnlockInfo> {
    constructor() {
        super("wuxiaworld.api.v2.RelatedChapterUserInfo.UserUnlockInfo", [
            { no: 1, name: "isUnlockedNow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "unlockNowMethod", kind: "enum", T: () => ["wuxiaworld.api.v2.UnlockItemMethod", UnlockItemMethod] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.RelatedChapterUserInfo.UserUnlockInfo
 */
export const RelatedChapterUserInfo_UserUnlockInfo = new RelatedChapterUserInfo_UserUnlockInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChapterItem$Type extends MessageType<ChapterItem> {
    constructor() {
        super("wuxiaworld.api.v2.ChapterItem", [
            { no: 1, name: "entityId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "number", kind: "message", T: () => DecimalValue },
            { no: 5, name: "content", kind: "message", T: () => StringValue },
            { no: 6, name: "novelId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "visible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "isTeaser", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "whenToPublish", kind: "message", T: () => Timestamp },
            { no: 10, name: "spoilerTitle", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "allowComments", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "relatedChapterInfo", kind: "message", T: () => RelatedChapterInfo },
            { no: 13, name: "karmaInfo", kind: "message", T: () => ChapterKarma },
            { no: 14, name: "novelInfo", kind: "message", T: () => ChapterNovelInfo },
            { no: 15, name: "sponsorInfo", kind: "message", T: () => ChapterSponsor },
            { no: 16, name: "relatedUserInfo", kind: "message", T: () => RelatedChapterUserInfo },
            { no: 17, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "publishedAt", kind: "message", T: () => Timestamp },
            { no: 19, name: "translatorThoughts", kind: "message", T: () => StringValue },
            { no: 20, name: "pricingInfo", kind: "message", T: () => ChapterPricing },
            { no: 21, name: "paragraphs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChapterParagraph },
            { no: 22, name: "audioInfo", kind: "message", T: () => ChapterAudioInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ChapterItem
 */
export const ChapterItem = new ChapterItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Chapters
 */
export const Chapters = new ServiceType("wuxiaworld.api.v2.Chapters", [
    { name: "GetChapterList", options: {}, I: GetChapterListRequest, O: GetChapterListResponse },
    { name: "GetChapter", options: {}, I: GetChapterRequest, O: GetChapterResponse },
    { name: "GetChapters", serverStreaming: true, options: {}, I: GetChaptersRequest, O: GetChaptersBatch },
    { name: "GetLatestChapters", options: {}, I: GetLatestChaptersRequest, O: GetLatestChaptersResponse }
]);
