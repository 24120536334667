import { ReactComponent as Bonus0 } from '@app/assets/cultivation-key-bonus-0.svg';
import { ReactComponent as Bonus1 } from '@app/assets/cultivation-key-bonus-1.svg';
import { ReactComponent as Bonus2 } from '@app/assets/cultivation-key-bonus-2.svg';
import clsx from 'clsx';

export type StreakDayItemProps = {
    dayInWeek: number;
    dayInStreak: number;
    isToday: boolean;
    isPending: boolean;
    rewardCount: number;
    bonusRewardNumber: number;
};

interface BonusIconProps extends React.SVGProps<SVGSVGElement> {
    bonusRewardNumber: number;
}

function BonusIcon({ bonusRewardNumber, ...rest }: BonusIconProps) {
    if (bonusRewardNumber === 0) {
        return <Bonus0 {...rest} />;
    }

    if (bonusRewardNumber === 1) {
        return <Bonus1 {...rest} />;
    }

    if (bonusRewardNumber === 2) {
        return <Bonus2 {...rest} />;
    }
}

export default function StreakDayItem({
    dayInWeek,
    dayInStreak,
    isToday,
    isPending,
    rewardCount,
    bonusRewardNumber,
}: StreakDayItemProps) {
    return (
        <div className="streaks-day-container">
            <div
                className={clsx('streaks-day', {
                    'day-crossed': !isPending,
                    'day-pending': isPending,
                    'day-today': isToday,
                })}
            >
                <div className="streaks-day-title">Day {dayInStreak}</div>
                <div className="streaks-day-img-container">
                    <BonusIcon bonusRewardNumber={bonusRewardNumber} className="text-orange-400 w-full h-full" />
                </div>
                <div className="streaks-day-reward">
                    {rewardCount} KEY{rewardCount > 1 ? 'S' : ''}
                </div>
            </div>
        </div>
    );
}
