// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/series.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { MessageType } from "@protobuf-ts/runtime";
import { GenreItem } from "./genres";
import { PricingModel } from "./pricing";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message wuxiaworld.api.v2.SeriesPricingInfo
 */
export interface SeriesPricingInfo {
    /**
     * @generated from protobuf oneof: info
     */
    info: {
        oneofKind: "waitToUnlock";
        /**
         * WTU
         *
         * @generated from protobuf field: wuxiaworld.api.v2.SeriesPricingInfo.WaitToUnlockInfo waitToUnlock = 1;
         */
        waitToUnlock: SeriesPricingInfo_WaitToUnlockInfo;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SeriesPricingInfo.WaitToUnlockInfo
 */
export interface SeriesPricingInfo_WaitToUnlockInfo {
    /**
     * Time when user started waiting
     *
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 1;
     */
    createdAt?: Timestamp;
    /**
     * Time when the user can unlock another chapter
     *
     * @generated from protobuf field: google.protobuf.Timestamp timeLeft = 2;
     */
    timeLeft?: Timestamp;
    /**
     * Has the wait time concluded
     *
     * @generated from protobuf field: bool waitCompleted = 3;
     */
    waitCompleted: boolean;
    /**
     * @generated from protobuf field: int32 unlocksLeft = 4;
     */
    unlocksLeft: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.RelatedSeriesUserInfo
 */
export interface RelatedSeriesUserInfo {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.SeriesPricingInfo pricingInfo = 1;
     */
    pricingInfo?: SeriesPricingInfo;
    /**
     * @generated from protobuf field: bool isUnlocked = 2;
     */
    isUnlocked: boolean;
    /**
     * @generated from protobuf field: bool hasEbook = 3;
     */
    hasEbook: boolean;
    /**
     * @generated from protobuf field: bool canAccess = 4;
     */
    canAccess: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SeriesItem
 */
export interface SeriesItem {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PricingModel pricingModel = 2;
     */
    pricingModel?: PricingModel;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.RelatedSeriesUserInfo relatedUserInfo = 3;
     */
    relatedUserInfo?: RelatedSeriesUserInfo;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.SeriesStatus status = 4;
     */
    status: SeriesStatus;
    /**
     * @generated from protobuf field: string type = 5;
     */
    type: string;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.GenreItem genres = 6;
     */
    genres: GenreItem[];
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.PricingModel activePricingModels = 7;
     */
    activePricingModels: PricingModel[];
    /**
     * @generated from protobuf field: bool isDelisted = 8;
     */
    isDelisted: boolean;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.SeriesStatus
 */
export enum SeriesStatus {
    /**
     * @generated from protobuf enum value: SeriesFinished = 0;
     */
    SeriesFinished = 0,
    /**
     * @generated from protobuf enum value: SeriesActive = 1;
     */
    SeriesActive = 1,
    /**
     * @generated from protobuf enum value: SeriesHiatus = 2;
     */
    SeriesHiatus = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class SeriesPricingInfo$Type extends MessageType<SeriesPricingInfo> {
    constructor() {
        super("wuxiaworld.api.v2.SeriesPricingInfo", [
            { no: 1, name: "waitToUnlock", kind: "message", oneof: "info", T: () => SeriesPricingInfo_WaitToUnlockInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SeriesPricingInfo
 */
export const SeriesPricingInfo = new SeriesPricingInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeriesPricingInfo_WaitToUnlockInfo$Type extends MessageType<SeriesPricingInfo_WaitToUnlockInfo> {
    constructor() {
        super("wuxiaworld.api.v2.SeriesPricingInfo.WaitToUnlockInfo", [
            { no: 1, name: "createdAt", kind: "message", T: () => Timestamp },
            { no: 2, name: "timeLeft", kind: "message", T: () => Timestamp },
            { no: 3, name: "waitCompleted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "unlocksLeft", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SeriesPricingInfo.WaitToUnlockInfo
 */
export const SeriesPricingInfo_WaitToUnlockInfo = new SeriesPricingInfo_WaitToUnlockInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelatedSeriesUserInfo$Type extends MessageType<RelatedSeriesUserInfo> {
    constructor() {
        super("wuxiaworld.api.v2.RelatedSeriesUserInfo", [
            { no: 1, name: "pricingInfo", kind: "message", T: () => SeriesPricingInfo },
            { no: 2, name: "isUnlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "hasEbook", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "canAccess", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.RelatedSeriesUserInfo
 */
export const RelatedSeriesUserInfo = new RelatedSeriesUserInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeriesItem$Type extends MessageType<SeriesItem> {
    constructor() {
        super("wuxiaworld.api.v2.SeriesItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "pricingModel", kind: "message", T: () => PricingModel },
            { no: 3, name: "relatedUserInfo", kind: "message", T: () => RelatedSeriesUserInfo },
            { no: 4, name: "status", kind: "enum", T: () => ["wuxiaworld.api.v2.SeriesStatus", SeriesStatus] },
            { no: 5, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "genres", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GenreItem },
            { no: 7, name: "activePricingModels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PricingModel },
            { no: 8, name: "isDelisted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SeriesItem
 */
export const SeriesItem = new SeriesItem$Type();
