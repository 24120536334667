// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/missions.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { KarmaTransactionItem } from "./karma";
import { StringValue } from "../google/protobuf/wrappers";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMissionSpecsRequest
 */
export interface GetMissionSpecsRequest {
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMissionSpecsResponse
 */
export interface GetMissionSpecsResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.MissionSpec specs = 1;
     */
    specs: MissionSpec[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyMissionProgressRequest
 */
export interface GetMyMissionProgressRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionItem.MissionType missionType = 1;
     */
    missionType: MissionItem_MissionType;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyMissionProgressResponse
 */
export interface GetMyMissionProgressResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.MissionProgress progress = 1;
     */
    progress: MissionProgress[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyCompletedMissionsRequest
 */
export interface GetMyCompletedMissionsRequest {
    /**
     * @generated from protobuf oneof: timeCompleted
     */
    timeCompleted: {
        oneofKind: "today";
        /**
         * @generated from protobuf field: bool today = 1;
         */
        today: boolean;
    } | {
        oneofKind: "date";
        /**
         * @generated from protobuf field: google.protobuf.Timestamp date = 2;
         */
        date: Timestamp;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyCompletedMissionsResponse
 */
export interface GetMyCompletedMissionsResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.MissionItem items = 1;
     */
    items: MissionItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.CompleteMissionRequest
 */
export interface CompleteMissionRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionItem.MissionType missionType = 1;
     */
    missionType: MissionItem_MissionType;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionItem.RewardType rewardType = 2;
     */
    rewardType: MissionItem_RewardType;
    /**
     * @generated from protobuf field: google.protobuf.StringValue missionSpecId = 3;
     */
    missionSpecId?: StringValue;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.CompleteMissionResponse
 */
export interface CompleteMissionResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionItem item = 1;
     */
    item?: MissionItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.MissionItem
 */
export interface MissionItem {
    /**
     * @generated from protobuf field: bool completed = 1;
     */
    completed: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp completedAt = 2;
     */
    completedAt?: Timestamp;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionItem.MissionType missionType = 3;
     */
    missionType: MissionItem_MissionType;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionItem.RewardType rewardType = 4;
     */
    rewardType: MissionItem_RewardType;
    /**
     * @generated from protobuf oneof: rewardItem
     */
    rewardItem: {
        oneofKind: "karma";
        /**
         * @generated from protobuf field: wuxiaworld.api.v2.KarmaTransactionItem karma = 5;
         */
        karma: KarmaTransactionItem;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.MissionItem.MissionType
 */
export enum MissionItem_MissionType {
    /**
     * @generated from protobuf enum value: MissionTypeNone = 0;
     */
    MissionTypeNone = 0,
    /**
     * @generated from protobuf enum value: MissionTypeCheckIn = 1;
     */
    MissionTypeCheckIn = 1,
    /**
     * @generated from protobuf enum value: MissionTypeComment = 2;
     */
    MissionTypeComment = 2,
    /**
     * @generated from protobuf enum value: MissionTypeRead = 3;
     */
    MissionTypeRead = 3
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.MissionItem.RewardType
 */
export enum MissionItem_RewardType {
    /**
     * @generated from protobuf enum value: RewardTypeNone = 0;
     */
    RewardTypeNone = 0,
    /**
     * @generated from protobuf enum value: RewardTypeKarma = 1;
     */
    RewardTypeKarma = 1,
    /**
     * @generated from protobuf enum value: RewardTypeVoucher = 2;
     */
    RewardTypeVoucher = 2
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.MissionSpec
 */
export interface MissionSpec {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionItem.MissionType missionType = 2;
     */
    missionType: MissionItem_MissionType;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionItem.RewardType rewardType = 3;
     */
    rewardType: MissionItem_RewardType;
    /**
     * @generated from protobuf field: int32 requiredCount = 4;
     */
    requiredCount: number;
    /**
     * @generated from protobuf field: int32 rewardAmount = 5;
     */
    rewardAmount: number;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue shortDescription = 7;
     */
    shortDescription?: StringValue;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.MissionProgress
 */
export interface MissionProgress {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionSpec missionSpec = 1;
     */
    missionSpec?: MissionSpec;
    /**
     * @generated from protobuf field: int32 count = 2;
     */
    count: number;
    /**
     * @generated from protobuf field: bool hasRedeemed = 3;
     */
    hasRedeemed: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetMissionSpecsRequest$Type extends MessageType<GetMissionSpecsRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetMissionSpecsRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMissionSpecsRequest
 */
export const GetMissionSpecsRequest = new GetMissionSpecsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMissionSpecsResponse$Type extends MessageType<GetMissionSpecsResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetMissionSpecsResponse", [
            { no: 1, name: "specs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MissionSpec }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMissionSpecsResponse
 */
export const GetMissionSpecsResponse = new GetMissionSpecsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyMissionProgressRequest$Type extends MessageType<GetMyMissionProgressRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyMissionProgressRequest", [
            { no: 1, name: "missionType", kind: "enum", T: () => ["wuxiaworld.api.v2.MissionItem.MissionType", MissionItem_MissionType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyMissionProgressRequest
 */
export const GetMyMissionProgressRequest = new GetMyMissionProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyMissionProgressResponse$Type extends MessageType<GetMyMissionProgressResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyMissionProgressResponse", [
            { no: 1, name: "progress", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MissionProgress }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyMissionProgressResponse
 */
export const GetMyMissionProgressResponse = new GetMyMissionProgressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyCompletedMissionsRequest$Type extends MessageType<GetMyCompletedMissionsRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyCompletedMissionsRequest", [
            { no: 1, name: "today", kind: "scalar", oneof: "timeCompleted", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "date", kind: "message", oneof: "timeCompleted", T: () => Timestamp }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyCompletedMissionsRequest
 */
export const GetMyCompletedMissionsRequest = new GetMyCompletedMissionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyCompletedMissionsResponse$Type extends MessageType<GetMyCompletedMissionsResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyCompletedMissionsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MissionItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyCompletedMissionsResponse
 */
export const GetMyCompletedMissionsResponse = new GetMyCompletedMissionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteMissionRequest$Type extends MessageType<CompleteMissionRequest> {
    constructor() {
        super("wuxiaworld.api.v2.CompleteMissionRequest", [
            { no: 1, name: "missionType", kind: "enum", T: () => ["wuxiaworld.api.v2.MissionItem.MissionType", MissionItem_MissionType] },
            { no: 2, name: "rewardType", kind: "enum", T: () => ["wuxiaworld.api.v2.MissionItem.RewardType", MissionItem_RewardType] },
            { no: 3, name: "missionSpecId", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.CompleteMissionRequest
 */
export const CompleteMissionRequest = new CompleteMissionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteMissionResponse$Type extends MessageType<CompleteMissionResponse> {
    constructor() {
        super("wuxiaworld.api.v2.CompleteMissionResponse", [
            { no: 1, name: "item", kind: "message", T: () => MissionItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.CompleteMissionResponse
 */
export const CompleteMissionResponse = new CompleteMissionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MissionItem$Type extends MessageType<MissionItem> {
    constructor() {
        super("wuxiaworld.api.v2.MissionItem", [
            { no: 1, name: "completed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "completedAt", kind: "message", T: () => Timestamp },
            { no: 3, name: "missionType", kind: "enum", T: () => ["wuxiaworld.api.v2.MissionItem.MissionType", MissionItem_MissionType] },
            { no: 4, name: "rewardType", kind: "enum", T: () => ["wuxiaworld.api.v2.MissionItem.RewardType", MissionItem_RewardType] },
            { no: 5, name: "karma", kind: "message", oneof: "rewardItem", T: () => KarmaTransactionItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.MissionItem
 */
export const MissionItem = new MissionItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MissionSpec$Type extends MessageType<MissionSpec> {
    constructor() {
        super("wuxiaworld.api.v2.MissionSpec", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "missionType", kind: "enum", T: () => ["wuxiaworld.api.v2.MissionItem.MissionType", MissionItem_MissionType] },
            { no: 3, name: "rewardType", kind: "enum", T: () => ["wuxiaworld.api.v2.MissionItem.RewardType", MissionItem_RewardType] },
            { no: 4, name: "requiredCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "rewardAmount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "shortDescription", kind: "message", T: () => StringValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.MissionSpec
 */
export const MissionSpec = new MissionSpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MissionProgress$Type extends MessageType<MissionProgress> {
    constructor() {
        super("wuxiaworld.api.v2.MissionProgress", [
            { no: 1, name: "missionSpec", kind: "message", T: () => MissionSpec },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "hasRedeemed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.MissionProgress
 */
export const MissionProgress = new MissionProgress$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Missions
 */
export const Missions = new ServiceType("wuxiaworld.api.v2.Missions", [
    { name: "GetMissionSpecs", options: {}, I: GetMissionSpecsRequest, O: GetMissionSpecsResponse },
    { name: "GetMyMissionProgress", options: {}, I: GetMyMissionProgressRequest, O: GetMyMissionProgressResponse },
    { name: "GetMyCompletedMissions", options: {}, I: GetMyCompletedMissionsRequest, O: GetMyCompletedMissionsResponse },
    { name: "CompleteMission", options: {}, I: CompleteMissionRequest, O: CompleteMissionResponse }
]);
