// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/unlocks.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Unlocks } from "./unlocks";
import type { BatchUnlockItemsResponse } from "./unlocks";
import type { BatchUnlockItemsRequest } from "./unlocks";
import type { UnlockItemResponse } from "./unlocks";
import type { UnlockItemRequest } from "./unlocks";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetUnlocksResponse } from "./unlocks";
import type { GetUnlocksRequest } from "./unlocks";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service wuxiaworld.api.v2.Unlocks
 */
export interface IUnlocksClient {
    /**
     * @generated from protobuf rpc: GetUnlocks(wuxiaworld.api.v2.GetUnlocksRequest) returns (wuxiaworld.api.v2.GetUnlocksResponse);
     */
    getUnlocks(input: GetUnlocksRequest, options?: RpcOptions): UnaryCall<GetUnlocksRequest, GetUnlocksResponse>;
    /**
     * @generated from protobuf rpc: UnlockItem(wuxiaworld.api.v2.UnlockItemRequest) returns (wuxiaworld.api.v2.UnlockItemResponse);
     */
    unlockItem(input: UnlockItemRequest, options?: RpcOptions): UnaryCall<UnlockItemRequest, UnlockItemResponse>;
    /**
     * @generated from protobuf rpc: BatchUnlockItems(wuxiaworld.api.v2.BatchUnlockItemsRequest) returns (wuxiaworld.api.v2.BatchUnlockItemsResponse);
     */
    batchUnlockItems(input: BatchUnlockItemsRequest, options?: RpcOptions): UnaryCall<BatchUnlockItemsRequest, BatchUnlockItemsResponse>;
}
/**
 * @generated from protobuf service wuxiaworld.api.v2.Unlocks
 */
export class UnlocksClient implements IUnlocksClient, ServiceInfo {
    typeName = Unlocks.typeName;
    methods = Unlocks.methods;
    options = Unlocks.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetUnlocks(wuxiaworld.api.v2.GetUnlocksRequest) returns (wuxiaworld.api.v2.GetUnlocksResponse);
     */
    getUnlocks(input: GetUnlocksRequest, options?: RpcOptions): UnaryCall<GetUnlocksRequest, GetUnlocksResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUnlocksRequest, GetUnlocksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UnlockItem(wuxiaworld.api.v2.UnlockItemRequest) returns (wuxiaworld.api.v2.UnlockItemResponse);
     */
    unlockItem(input: UnlockItemRequest, options?: RpcOptions): UnaryCall<UnlockItemRequest, UnlockItemResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UnlockItemRequest, UnlockItemResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchUnlockItems(wuxiaworld.api.v2.BatchUnlockItemsRequest) returns (wuxiaworld.api.v2.BatchUnlockItemsResponse);
     */
    batchUnlockItems(input: BatchUnlockItemsRequest, options?: RpcOptions): UnaryCall<BatchUnlockItemsRequest, BatchUnlockItemsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchUnlockItemsRequest, BatchUnlockItemsResponse>("unary", this._transport, method, opt, input);
    }
}
