// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/discounts.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
import { StringValue } from "../google/protobuf/wrappers";
import { DecimalValue } from "./types";
/**
 * @generated from protobuf message wuxiaworld.api.v2.Discount
 */
export interface Discount {
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "percentage";
        /**
         * @generated from protobuf field: int32 percentage = 1;
         */
        percentage: number;
    } | {
        oneofKind: "fixed";
        /**
         * @generated from protobuf field: customTypes.DecimalValue fixed = 2;
         */
        fixed: DecimalValue;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: google.protobuf.StringValue description = 3;
     */
    description?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue shortDescription = 4;
     */
    shortDescription?: StringValue;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.Discount.PlatformAvailability platformAvailability = 5;
     */
    platformAvailability: Discount_PlatformAvailability[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp endsAt = 6;
     */
    endsAt?: Timestamp;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.Discount.PlatformAvailability
 */
export enum Discount_PlatformAvailability {
    /**
     * @generated from protobuf enum value: Web = 0;
     */
    Web = 0,
    /**
     * @generated from protobuf enum value: Android = 1;
     */
    Android = 1,
    /**
     * @generated from protobuf enum value: Ios = 2;
     */
    Ios = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Discount$Type extends MessageType<Discount> {
    constructor() {
        super("wuxiaworld.api.v2.Discount", [
            { no: 1, name: "percentage", kind: "scalar", oneof: "value", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "fixed", kind: "message", oneof: "value", T: () => DecimalValue },
            { no: 3, name: "description", kind: "message", T: () => StringValue },
            { no: 4, name: "shortDescription", kind: "message", T: () => StringValue },
            { no: 5, name: "platformAvailability", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["wuxiaworld.api.v2.Discount.PlatformAvailability", Discount_PlatformAvailability] },
            { no: 6, name: "endsAt", kind: "message", T: () => Timestamp }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.Discount
 */
export const Discount = new Discount$Type();
