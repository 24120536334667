import ButtonLink from '@app/components/button.link';
import { HEADER_HEIGHT_DOWN_SM, HEADER_HEIGHT_ONLY_SM, HEADER_HEIGHT_UP_MD } from '@app/domains/header/constants';
import { breakpoints } from '@app/utils/breakpoints';
import { useGenerateImgAttrs } from '@app/utils/utils';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max) + 1;
}

function NotFoundBgImg() {
    const imageNumber = useMemo(() => getRandomInt(3), []);
    const imageAttrs = useGenerateImgAttrs({
        src: `/images/404_${imageNumber}.jpg`,
        sizes: '100vw',
        quality: 75,
        layout: 'responsive',
        unoptimized: false,
    });

    return (
        <div css={StyledImageContainer} className="absolute left-0 right-0 bottom-0">
            <div
                css={theme => ({
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 1%, rgba(0, 0, 0, 0.5))`,
                        backgroundSize: 'cover',
                        [theme.breakpoints.down(1280)]: {
                            backgroundPosition: 'center',
                        },
                    },
                })}
                className="z-10 h-full"
            >
                <img className="z-0 h-full w-full object-cover [object-position:center_top]" {...imageAttrs} />
            </div>
        </div>
    );
}

export default function NoLongerAvailable() {
    return (
        <>
            <Helmet defer={false}>
                <meta name="robots" content="noindex, nofollow" />
                <title>No longer available</title>
            </Helmet>
            <NotFoundBgImg />
            <div className="flex h-[100%] flex-1 flex-col items-center justify-center leading-tight text-white">
                <div className="z-10 mt-[22px] text-center text-[17px] font-semibold">
                    This page is no longer available.
                </div>
                <div className="z-10 mt-[42px]">
                    <ButtonLink to="/">Back to Home</ButtonLink>
                </div>
            </div>
        </>
    );
}

export const StyledImageContainer = css({
    [breakpoints.upMd]: {
        top: `${HEADER_HEIGHT_UP_MD}px`,
        height: `calc(100% - ${HEADER_HEIGHT_UP_MD}px)`,
    },
    [breakpoints.onlySm]: {
        top: `${HEADER_HEIGHT_ONLY_SM}px`,
        height: `calc(100% - ${HEADER_HEIGHT_ONLY_SM}px)`,
    },
    [breakpoints.downSm]: {
        top: `${HEADER_HEIGHT_DOWN_SM}px`,
        height: `calc(100% - ${HEADER_HEIGHT_DOWN_SM}px)`,
    },
});
