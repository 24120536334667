import { ReactComponent as AnnivFullLogo } from '@app/assets/ww-anniv-full.svg';
import { ReactComponent as AnnivShortLogo } from '@app/assets/ww-anniv-short.svg';
import { ReactComponent as Logo } from '@app/assets/ww-character.svg';
import type { ClassNameType } from '@app/domains/common/types';
import { HeaderEvents } from '@app/domains/header/analytics/amplitude/events';
import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

function Brand({ children }: PropsWithChildren<{}>) {
    return (
        <Link
            className="inline-flex items-center space-x-6 text-gray-t0"
            to="/"
            aria-label="Wuxiaworld"
            data-amplitude-click-event={HeaderEvents.ClickWuxiaLogo}
        >
            {children}
        </Link>
    );
}

const LogoIcon = () => {
    return <Logo />;
};

const AnnivLogo = () => {
    return (
        <>
            <AnnivShortLogo className="sm:hidden w-[100px] h-auto" />
            <AnnivFullLogo className="hidden sm:inline w-[250px] h-auto" />
        </>
    );
};

const Name = ({ className }: ClassNameType) => {
    return <span className={twMerge(clsx('font-set-sb18 tracking-[1.29px] text-gray-t0', className))}>WUXIAWORLD</span>;
};

Brand.LogoIcon = LogoIcon;
Brand.Name = Name;

Brand.AnnivLogo = AnnivLogo;

export default Brand;
