export enum HeaderEvents {
    ClickWuxiaLogo = 'HeaderEvents.ClickWuxiaLogo',
    ClickSeriesTab = 'HeaderEvents.ClickSeriesTab',
    ClickBookmarksTab = 'HeaderEvents.ClickBookmarksTab',
    ClickResourcesTab = 'HeaderEvents.ClickResourcesTab',
    ClickAudiobooksTab = 'HeaderEvents.ClickAudiobooksTab',
    ClickEbooksTab = 'HeaderEvents.ClickEbooksTab',
    Search = 'HeaderEvents.Search',
    ClickSearchResult = 'HeaderEvents.ClickSearchResult',
    ClickStore = 'HeaderEvents.ClickStore',
    ClickVipSubscription = 'HeaderEvents.ClickVipSubscription',
    ClickKarma = 'HeaderEvents.ClickKarma',
    ClickBilling = 'HeaderEvents.ClickBilling',
    ClickFaq = 'HeaderEvents.ClickFaq',
    ClickVip = 'HeaderEvents.ClickVip',
    ClickNotification = 'HeaderEvents.ClickNotification',
    ClickNotificationPage = 'HeaderEvents.ClickNotificationPage',
    ClickMyPage = 'HeaderEvents.ClickMyPage',
    ClickProfile = 'HeaderEvents.ClickProfile',
    ClickNotifications = 'HeaderEvents.ClickNotifications',
    ClickMyAudiobooks = 'HeaderEvents.ClickMyAudiobooks',
    ClickMyEbooks = 'HeaderEvents.ClickMyEbooks',
    ClickLogout = 'HeaderEvents.ClickLogout',
    ClickSetting = 'HeaderEvents.ClickSetting',
    ClickMode = 'HeaderEvents.ClickMode',
    ClickChampion = 'HeaderEvents.ClickChampion',
    ClickLoginCheckIn = 'HeaderEvents.ClickLoginCheckIn',
}

export const HEADER_EVENT_NAMES = {
    [HeaderEvents.ClickWuxiaLogo]: 'Click Wuxia Logo',
    [HeaderEvents.ClickSeriesTab]: 'Click Series Tab',
    [HeaderEvents.ClickBookmarksTab]: 'Click Bookmarks Tab',
    [HeaderEvents.ClickResourcesTab]: 'Click Resources Tab',
    [HeaderEvents.ClickAudiobooksTab]: 'Click Audiobook Tab',
    [HeaderEvents.ClickEbooksTab]: 'Click Ebook Tab',
    [HeaderEvents.Search]: 'Search',
    [HeaderEvents.ClickSearchResult]: 'Click Search Result',
    [HeaderEvents.ClickStore]: 'Click Store',
    [HeaderEvents.ClickVipSubscription]: 'Click VIP Subscription',
    [HeaderEvents.ClickKarma]: 'Click Karma',
    [HeaderEvents.ClickBilling]: 'Click Billing',
    [HeaderEvents.ClickFaq]: 'Click FAQ',
    [HeaderEvents.ClickVip]: 'Click VIP',
    [HeaderEvents.ClickNotification]: 'Click Notification',
    [HeaderEvents.ClickNotificationPage]: 'Click Notification Page',
    [HeaderEvents.ClickMyPage]: 'Click My Page',
    [HeaderEvents.ClickProfile]: 'Click Profile',
    [HeaderEvents.ClickNotifications]: 'Click Notifications',
    [HeaderEvents.ClickMyAudiobooks]: 'Click My Audiobooks',
    [HeaderEvents.ClickMyEbooks]: 'Click My Ebooks',
    [HeaderEvents.ClickSetting]: 'Click Setting',
    [HeaderEvents.ClickMode]: 'Click Mode',
    [HeaderEvents.ClickChampion]: 'Click Champion',
    [HeaderEvents.ClickLoginCheckIn]: 'Click Login Check In',
};
