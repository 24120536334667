// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/vips.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { Empty } from "../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { ProductItem } from "./products";
import { DecimalValue } from "./types";
import { StringValue } from "../google/protobuf/wrappers";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetVipPackagesResponse
 */
export interface GetVipPackagesResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.VipItem items = 1;
     */
    items: VipItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.VipItem
 */
export interface VipItem {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string planId = 3;
     */
    planId: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue description = 4;
     */
    description?: StringValue;
    /**
     * @generated from protobuf field: customTypes.DecimalValue price = 5;
     */
    price?: DecimalValue;
    /**
     * @generated from protobuf field: int32 interval = 6;
     */
    interval: number;
    /**
     * @generated from protobuf field: bool enabled = 7;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: bool visible = 8;
     */
    visible: boolean;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.VipItem.VipType type = 9;
     */
    type: VipItem_VipType;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.VipItem.VipBenefits benefits = 10;
     */
    benefits: VipItem_VipBenefits[];
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ProductItem product = 11;
     */
    product?: ProductItem;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.VipItem.VipType
 */
export enum VipItem_VipType {
    /**
     * @generated from protobuf enum value: Silver = 0;
     */
    Silver = 0,
    /**
     * @generated from protobuf enum value: Gold = 1;
     */
    Gold = 1,
    /**
     * @generated from protobuf enum value: Diamond = 2;
     */
    Diamond = 2
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.VipItem.VipBenefits
 */
export enum VipItem_VipBenefits {
    /**
     * @generated from protobuf enum value: None = 0;
     */
    None = 0,
    /**
     * @generated from protobuf enum value: NoAds = 1;
     */
    NoAds = 1,
    /**
     * @generated from protobuf enum value: FreeNovel = 2;
     */
    FreeNovel = 2,
    /**
     * @generated from protobuf enum value: CompletedNovels = 3;
     */
    CompletedNovels = 3,
    /**
     * @generated from protobuf enum value: NovelVoucher = 4;
     */
    NovelVoucher = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class GetVipPackagesResponse$Type extends MessageType<GetVipPackagesResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetVipPackagesResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VipItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetVipPackagesResponse
 */
export const GetVipPackagesResponse = new GetVipPackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VipItem$Type extends MessageType<VipItem> {
    constructor() {
        super("wuxiaworld.api.v2.VipItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "planId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "message", T: () => StringValue },
            { no: 5, name: "price", kind: "message", T: () => DecimalValue },
            { no: 6, name: "interval", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "visible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "type", kind: "enum", T: () => ["wuxiaworld.api.v2.VipItem.VipType", VipItem_VipType] },
            { no: 10, name: "benefits", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["wuxiaworld.api.v2.VipItem.VipBenefits", VipItem_VipBenefits] },
            { no: 11, name: "product", kind: "message", T: () => ProductItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.VipItem
 */
export const VipItem = new VipItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Vips
 */
export const Vips = new ServiceType("wuxiaworld.api.v2.Vips", [
    { name: "GetVipPackages", options: {}, I: Empty, O: GetVipPackagesResponse }
]);
