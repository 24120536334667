// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/notifications.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { Empty } from "../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
import { Int32Value } from "../google/protobuf/wrappers";
import { StringValue } from "../google/protobuf/wrappers";
import { PageInfoResponse } from "./pagination";
import { PageInfoRequest } from "./pagination";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyNotificationsRequest
 */
export interface GetMyNotificationsRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.NotificationItem.Status status = 1;
     */
    status: NotificationItem_Status;
    /**
     * @generated from protobuf field: repeated string groups = 2;
     */
    groups: string[];
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PageInfoRequest pageInfo = 3;
     */
    pageInfo?: PageInfoRequest;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyNotificationsResponse
 */
export interface GetMyNotificationsResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.NotificationItem items = 1;
     */
    items: NotificationItem[];
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PageInfoResponse pageInfo = 2;
     */
    pageInfo?: PageInfoResponse;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyNotificationsCountRequest
 */
export interface GetMyNotificationsCountRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.NotificationItem.Status status = 1;
     */
    status: NotificationItem_Status;
    /**
     * @generated from protobuf field: repeated string groups = 2;
     */
    groups: string[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SetNotificationStatusRequest
 */
export interface SetNotificationStatusRequest {
    /**
     * @generated from protobuf oneof: id
     */
    id: {
        oneofKind: "objectId";
        /**
         * @generated from protobuf field: string objectId = 1;
         */
        objectId: string;
    } | {
        oneofKind: "entityId";
        /**
         * @generated from protobuf field: int32 entityId = 3;
         */
        entityId: number;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.NotificationItem.Status status = 2;
     */
    status: NotificationItem_Status;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SetNotificationStatusResponse
 */
export interface SetNotificationStatusResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SetNotificationStatusesRequest
 */
export interface SetNotificationStatusesRequest {
    /**
     * @generated from protobuf field: google.protobuf.StringValue group = 1;
     */
    group?: StringValue;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.NotificationItem.Status status = 2;
     */
    status: NotificationItem_Status;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SetNotificationStatusesResponse
 */
export interface SetNotificationStatusesResponse {
    /**
     * @generated from protobuf field: bool result = 1;
     */
    result: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.SetNotificationSettingsRequest
 */
export interface SetNotificationSettingsRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.NotificationSettingsItem.Type type = 1;
     */
    type: NotificationSettingsItem_Type;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.NotificationSettingsItem.Target target = 2;
     */
    target: NotificationSettingsItem_Target;
    /**
     * @generated from protobuf field: bool isEnabled = 3;
     */
    isEnabled: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyNotificationSettingsResponse
 */
export interface GetMyNotificationSettingsResponse {
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.NotificationSettingsItem items = 1;
     */
    items: NotificationSettingsItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetMyNotificationsCountResponse
 */
export interface GetMyNotificationsCountResponse {
    /**
     * @generated from protobuf field: int32 count = 1;
     */
    count: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.RegisterPushNotificationTokenRequest
 */
export interface RegisterPushNotificationTokenRequest {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PushNotificationType type = 2;
     */
    type: PushNotificationType;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.DeletePushNotificationTokenRequest
 */
export interface DeletePushNotificationTokenRequest {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.NotificationItem
 */
export interface NotificationItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string objectId = 2;
     */
    objectId: string;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value entityId = 3;
     */
    entityId?: Int32Value;
    /**
     * @generated from protobuf field: string type = 4;
     */
    type: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.NotificationItem.Status status = 5;
     */
    status: NotificationItem_Status;
    /**
     * @generated from protobuf field: google.protobuf.StringValue actor = 6;
     */
    actor?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue slug = 7;
     */
    slug?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdOn = 8;
     */
    createdOn?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timeRead = 9;
     */
    timeRead?: Timestamp;
    /**
     * @generated from protobuf field: map<string, string> data = 10;
     */
    data: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: map<string, string> extraContent = 11;
     */
    extraContent: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.NotificationItem.Status
 */
export enum NotificationItem_Status {
    /**
     * @generated from protobuf enum value: Unread = 0;
     */
    Unread = 0,
    /**
     * @generated from protobuf enum value: Read = 1;
     */
    Read = 1,
    /**
     * @generated from protobuf enum value: All = 2;
     */
    All = 2
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.NotificationSettingsItem
 */
export interface NotificationSettingsItem {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.NotificationSettingsItem.Type type = 1;
     */
    type: NotificationSettingsItem_Type;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.NotificationSettingsItem.Target target = 2;
     */
    target: NotificationSettingsItem_Target;
    /**
     * @generated from protobuf field: bool isEnabled = 3;
     */
    isEnabled: boolean;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.NotificationSettingsItem.Type
 */
export enum NotificationSettingsItem_Type {
    /**
     * @generated from protobuf enum value: EmptyType = 0;
     */
    EmptyType = 0,
    /**
     * @generated from protobuf enum value: Chapters = 1;
     */
    Chapters = 1,
    /**
     * @generated from protobuf enum value: Comments = 2;
     */
    Comments = 2,
    /**
     * @generated from protobuf enum value: Posts = 3;
     */
    Posts = 3,
    /**
     * @generated from protobuf enum value: Announcements = 4;
     */
    Announcements = 4,
    /**
     * @generated from protobuf enum value: WaitToUnlock = 5;
     */
    WaitToUnlock = 5,
    /**
     * @generated from protobuf enum value: DailyRewards = 6;
     */
    DailyRewards = 6
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.NotificationSettingsItem.Target
 */
export enum NotificationSettingsItem_Target {
    /**
     * @generated from protobuf enum value: EmptyTarget = 0;
     */
    EmptyTarget = 0,
    /**
     * @generated from protobuf enum value: Push = 1;
     */
    Push = 1,
    /**
     * @generated from protobuf enum value: Email = 2;
     */
    Email = 2,
    /**
     * @generated from protobuf enum value: Web = 3;
     */
    Web = 3
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.PushNotificationType
 */
export enum PushNotificationType {
    /**
     * @generated from protobuf enum value: PushNone = 0;
     */
    PushNone = 0,
    /**
     * @generated from protobuf enum value: PushWeb = 1;
     */
    PushWeb = 1,
    /**
     * @generated from protobuf enum value: PushMobile = 2;
     */
    PushMobile = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class GetMyNotificationsRequest$Type extends MessageType<GetMyNotificationsRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyNotificationsRequest", [
            { no: 1, name: "status", kind: "enum", T: () => ["wuxiaworld.api.v2.NotificationItem.Status", NotificationItem_Status] },
            { no: 2, name: "groups", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pageInfo", kind: "message", T: () => PageInfoRequest }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyNotificationsRequest
 */
export const GetMyNotificationsRequest = new GetMyNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyNotificationsResponse$Type extends MessageType<GetMyNotificationsResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyNotificationsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationItem },
            { no: 2, name: "pageInfo", kind: "message", T: () => PageInfoResponse }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyNotificationsResponse
 */
export const GetMyNotificationsResponse = new GetMyNotificationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyNotificationsCountRequest$Type extends MessageType<GetMyNotificationsCountRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyNotificationsCountRequest", [
            { no: 1, name: "status", kind: "enum", T: () => ["wuxiaworld.api.v2.NotificationItem.Status", NotificationItem_Status] },
            { no: 2, name: "groups", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyNotificationsCountRequest
 */
export const GetMyNotificationsCountRequest = new GetMyNotificationsCountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetNotificationStatusRequest$Type extends MessageType<SetNotificationStatusRequest> {
    constructor() {
        super("wuxiaworld.api.v2.SetNotificationStatusRequest", [
            { no: 1, name: "objectId", kind: "scalar", oneof: "id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "entityId", kind: "scalar", oneof: "id", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "status", kind: "enum", T: () => ["wuxiaworld.api.v2.NotificationItem.Status", NotificationItem_Status] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SetNotificationStatusRequest
 */
export const SetNotificationStatusRequest = new SetNotificationStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetNotificationStatusResponse$Type extends MessageType<SetNotificationStatusResponse> {
    constructor() {
        super("wuxiaworld.api.v2.SetNotificationStatusResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SetNotificationStatusResponse
 */
export const SetNotificationStatusResponse = new SetNotificationStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetNotificationStatusesRequest$Type extends MessageType<SetNotificationStatusesRequest> {
    constructor() {
        super("wuxiaworld.api.v2.SetNotificationStatusesRequest", [
            { no: 1, name: "group", kind: "message", T: () => StringValue },
            { no: 2, name: "status", kind: "enum", T: () => ["wuxiaworld.api.v2.NotificationItem.Status", NotificationItem_Status] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SetNotificationStatusesRequest
 */
export const SetNotificationStatusesRequest = new SetNotificationStatusesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetNotificationStatusesResponse$Type extends MessageType<SetNotificationStatusesResponse> {
    constructor() {
        super("wuxiaworld.api.v2.SetNotificationStatusesResponse", [
            { no: 1, name: "result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SetNotificationStatusesResponse
 */
export const SetNotificationStatusesResponse = new SetNotificationStatusesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetNotificationSettingsRequest$Type extends MessageType<SetNotificationSettingsRequest> {
    constructor() {
        super("wuxiaworld.api.v2.SetNotificationSettingsRequest", [
            { no: 1, name: "type", kind: "enum", T: () => ["wuxiaworld.api.v2.NotificationSettingsItem.Type", NotificationSettingsItem_Type] },
            { no: 2, name: "target", kind: "enum", T: () => ["wuxiaworld.api.v2.NotificationSettingsItem.Target", NotificationSettingsItem_Target] },
            { no: 3, name: "isEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.SetNotificationSettingsRequest
 */
export const SetNotificationSettingsRequest = new SetNotificationSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyNotificationSettingsResponse$Type extends MessageType<GetMyNotificationSettingsResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyNotificationSettingsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationSettingsItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyNotificationSettingsResponse
 */
export const GetMyNotificationSettingsResponse = new GetMyNotificationSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyNotificationsCountResponse$Type extends MessageType<GetMyNotificationsCountResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetMyNotificationsCountResponse", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetMyNotificationsCountResponse
 */
export const GetMyNotificationsCountResponse = new GetMyNotificationsCountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterPushNotificationTokenRequest$Type extends MessageType<RegisterPushNotificationTokenRequest> {
    constructor() {
        super("wuxiaworld.api.v2.RegisterPushNotificationTokenRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["wuxiaworld.api.v2.PushNotificationType", PushNotificationType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.RegisterPushNotificationTokenRequest
 */
export const RegisterPushNotificationTokenRequest = new RegisterPushNotificationTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeletePushNotificationTokenRequest$Type extends MessageType<DeletePushNotificationTokenRequest> {
    constructor() {
        super("wuxiaworld.api.v2.DeletePushNotificationTokenRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.DeletePushNotificationTokenRequest
 */
export const DeletePushNotificationTokenRequest = new DeletePushNotificationTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationItem$Type extends MessageType<NotificationItem> {
    constructor() {
        super("wuxiaworld.api.v2.NotificationItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "objectId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "entityId", kind: "message", T: () => Int32Value },
            { no: 4, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "status", kind: "enum", T: () => ["wuxiaworld.api.v2.NotificationItem.Status", NotificationItem_Status] },
            { no: 6, name: "actor", kind: "message", T: () => StringValue },
            { no: 7, name: "slug", kind: "message", T: () => StringValue },
            { no: 8, name: "createdOn", kind: "message", T: () => Timestamp },
            { no: 9, name: "timeRead", kind: "message", T: () => Timestamp },
            { no: 10, name: "data", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 11, name: "extraContent", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.NotificationItem
 */
export const NotificationItem = new NotificationItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationSettingsItem$Type extends MessageType<NotificationSettingsItem> {
    constructor() {
        super("wuxiaworld.api.v2.NotificationSettingsItem", [
            { no: 1, name: "type", kind: "enum", T: () => ["wuxiaworld.api.v2.NotificationSettingsItem.Type", NotificationSettingsItem_Type] },
            { no: 2, name: "target", kind: "enum", T: () => ["wuxiaworld.api.v2.NotificationSettingsItem.Target", NotificationSettingsItem_Target] },
            { no: 3, name: "isEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.NotificationSettingsItem
 */
export const NotificationSettingsItem = new NotificationSettingsItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Notifications
 */
export const Notifications = new ServiceType("wuxiaworld.api.v2.Notifications", [
    { name: "GetMyNotifications", options: {}, I: GetMyNotificationsRequest, O: GetMyNotificationsResponse },
    { name: "GetMyNotificationsCount", options: {}, I: GetMyNotificationsCountRequest, O: GetMyNotificationsCountResponse },
    { name: "GetMyNotificationSettings", options: {}, I: Empty, O: GetMyNotificationSettingsResponse },
    { name: "SetNotificationStatus", options: {}, I: SetNotificationStatusRequest, O: SetNotificationStatusResponse },
    { name: "SetNotificationStatuses", options: {}, I: SetNotificationStatusesRequest, O: SetNotificationStatusesResponse },
    { name: "SetNotificationSettings", options: {}, I: SetNotificationSettingsRequest, O: Empty },
    { name: "RegisterPushNotificationToken", options: {}, I: RegisterPushNotificationTokenRequest, O: Empty },
    { name: "DeletePushNotificationToken", options: {}, I: DeletePushNotificationTokenRequest, O: Empty }
]);
