import { ReactComponent as CultivationKeyIcon } from '@app/assets/cultivation-key.svg';
import { ReactComponent as Unlock } from '@app/assets/unlock-alt-2.svg';
import { useTheme } from '@app/components/hooks';
import { Button, Dialog, DialogContent, Portal } from '@mui/material';
import { YinYang } from 'mdi-material-ui';

export type VouchersIntroDialogProps = {
    open: boolean;
    onClose: () => void;
};

export default function VouchersIntroDialog({ open, onClose }: VouchersIntroDialogProps) {
    const theme = useTheme();

    return (
        <Portal>
            <Dialog
                open={open}
                onClose={onClose}
                className="max-w-full max-h-full overflow-auto"
                PaperProps={{ className: '!max-w-[327px] sm:!max-w-[400px] rounded-[28px] m-[16px] md:m-[24px]' }}
                componentsProps={{ backdrop: { className: 'bg-black/80' } }}
            >
                <DialogContent className="dark:bg-gray-950 p-24 styled-scrollbar">
                    <div className="grid grid-flow-row gap-y-16">
                        <img
                            src={
                                theme.name === 'light'
                                    ? '/images/karma-to-vouchers-light.png'
                                    : '/images/karma-to-vouchers-dark.png'
                            }
                            alt=""
                            className="mx-auto"
                        />
                        <div className="text-center text-[24px] font-bold leading-[28px]">
                            Cultivation keys will be
                            <br />
                            replacing Regular Karma
                        </div>
                        <div className="flex items-center">
                            <CultivationKeyIcon className="w-[22px] h-[22px] mr-16 text-orange-400" />
                            <div className="flex-1 text-[14px] leading-[17px] text-gray-800 dark:text-gray-400">
                                This icon will represent the cultivation key balance in your account.
                            </div>
                        </div>
                        <div className="flex items-center">
                            <Unlock className="w-[22px] h-[22px] mr-16 text-gray-800 dark:text-white" />
                            <div className="flex-1 text-[14px] leading-[17px] text-gray-800 dark:text-gray-400">
                                A cultivation key can unlock a chapter of a novel.
                            </div>
                        </div>
                        <div className="flex items-center">
                            <YinYang className="w-[22px] h-[22px] mr-16 text-gray-800 dark:text-white" />
                            <div className="flex-1 text-[14px] leading-[17px] text-gray-800 dark:text-gray-400">
                                Your Regular Karma will remain intact and can still be used.
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-32 mb-24">
                        <Button
                            href="/news/the-cultivation-key-system-has-been-implemented"
                            onClick={onClose}
                            variant="text"
                            color="primary"
                            className="uppercase !text-[16px] leading-[19px] !no-underline"
                        >
                            Learn more
                        </Button>
                    </div>
                    <div className="text-center">
                        <Button
                            className="min-h-[36px] min-w-180 sm:min-w-240 max-w-full text-[16px] leading-[19px] uppercase"
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={onClose}
                        >
                            Got it
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </Portal>
    );
}
