// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/vouchers.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { Empty } from "../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { UnlockedItem } from "./unlocks";
import { MissionItem } from "./missions";
import { Timestamp } from "../google/protobuf/timestamp";
import { StringValue } from "../google/protobuf/wrappers";
import { PageInfoResponse } from "./pagination";
import { BoolValue } from "../google/protobuf/wrappers";
import { PageInfoRequest } from "./pagination";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetVoucherCountsResponse
 */
export interface GetVoucherCountsResponse {
    /**
     * Voucher counts by type
     *
     * @generated from protobuf field: map<int32, int32> voucherCounts = 1;
     */
    voucherCounts: {
        [key: number]: number;
    };
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetVoucherHistoryRequest
 */
export interface GetVoucherHistoryRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PageInfoRequest pageInfo = 1;
     */
    pageInfo?: PageInfoRequest;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.GetVoucherHistoryRequest.Filter filter = 2;
     */
    filter?: GetVoucherHistoryRequest_Filter;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.GetVoucherHistoryRequest.SortBy sortBy = 3;
     */
    sortBy: GetVoucherHistoryRequest_SortBy;
    /**
     * @generated from protobuf field: bool sortAsc = 4;
     */
    sortAsc: boolean;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetVoucherHistoryRequest.Filter
 */
export interface GetVoucherHistoryRequest_Filter {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.VoucherItem.VoucherType voucherType = 1;
     */
    voucherType: VoucherItem_VoucherType;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isRedeemed = 2;
     */
    isRedeemed?: BoolValue;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.GetVoucherHistoryRequest.SortBy
 */
export enum GetVoucherHistoryRequest_SortBy {
    /**
     * @generated from protobuf enum value: CreatedAt = 0;
     */
    CreatedAt = 0,
    /**
     * @generated from protobuf enum value: RedeemedAt = 1;
     */
    RedeemedAt = 1
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetVoucherHistoryResponse
 */
export interface GetVoucherHistoryResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.PageInfoResponse pageInfo = 1;
     */
    pageInfo?: PageInfoResponse;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.VoucherHistoryEntry entries = 2;
     */
    entries: VoucherHistoryEntry[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.VoucherHistoryEntry
 */
export interface VoucherHistoryEntry {
    /**
     * @generated from protobuf field: google.protobuf.StringValue groupKey = 2;
     */
    groupKey?: StringValue;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.VoucherItem items = 3;
     */
    items: VoucherItem[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.VoucherItem
 */
export interface VoucherItem {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: bool isRedeemed = 2;
     */
    isRedeemed: boolean;
    /**
     * @generated from protobuf field: string userId = 3;
     */
    userId: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.VoucherItem.VoucherType type = 4;
     */
    type: VoucherItem_VoucherType;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 5;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp redeemedAt = 6;
     */
    redeemedAt?: Timestamp;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.MissionItem completedMission = 7;
     */
    completedMission?: MissionItem;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UnlockedItem chapterPurchase = 8;
     */
    chapterPurchase?: UnlockedItem;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.VoucherItem.VoucherType
 */
export enum VoucherItem_VoucherType {
    /**
     * @generated from protobuf enum value: VoucherTypeUnknown = 0;
     */
    VoucherTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: VoucherTypeAll = 1;
     */
    VoucherTypeAll = 1,
    /**
     * @generated from protobuf enum value: VoucherTypeEbooks = 2;
     */
    VoucherTypeEbooks = 2,
    /**
     * @generated from protobuf enum value: VoucherTypeChapters = 3;
     */
    VoucherTypeChapters = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class GetVoucherCountsResponse$Type extends MessageType<GetVoucherCountsResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetVoucherCountsResponse", [
            { no: 1, name: "voucherCounts", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetVoucherCountsResponse
 */
export const GetVoucherCountsResponse = new GetVoucherCountsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVoucherHistoryRequest$Type extends MessageType<GetVoucherHistoryRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetVoucherHistoryRequest", [
            { no: 1, name: "pageInfo", kind: "message", T: () => PageInfoRequest, options: { "wuxiaworld.api.v2.required": { value: true } } },
            { no: 2, name: "filter", kind: "message", T: () => GetVoucherHistoryRequest_Filter },
            { no: 3, name: "sortBy", kind: "enum", T: () => ["wuxiaworld.api.v2.GetVoucherHistoryRequest.SortBy", GetVoucherHistoryRequest_SortBy] },
            { no: 4, name: "sortAsc", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetVoucherHistoryRequest
 */
export const GetVoucherHistoryRequest = new GetVoucherHistoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVoucherHistoryRequest_Filter$Type extends MessageType<GetVoucherHistoryRequest_Filter> {
    constructor() {
        super("wuxiaworld.api.v2.GetVoucherHistoryRequest.Filter", [
            { no: 1, name: "voucherType", kind: "enum", T: () => ["wuxiaworld.api.v2.VoucherItem.VoucherType", VoucherItem_VoucherType] },
            { no: 2, name: "isRedeemed", kind: "message", T: () => BoolValue }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetVoucherHistoryRequest.Filter
 */
export const GetVoucherHistoryRequest_Filter = new GetVoucherHistoryRequest_Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVoucherHistoryResponse$Type extends MessageType<GetVoucherHistoryResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetVoucherHistoryResponse", [
            { no: 1, name: "pageInfo", kind: "message", T: () => PageInfoResponse },
            { no: 2, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VoucherHistoryEntry }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetVoucherHistoryResponse
 */
export const GetVoucherHistoryResponse = new GetVoucherHistoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VoucherHistoryEntry$Type extends MessageType<VoucherHistoryEntry> {
    constructor() {
        super("wuxiaworld.api.v2.VoucherHistoryEntry", [
            { no: 2, name: "groupKey", kind: "message", T: () => StringValue },
            { no: 3, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VoucherItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.VoucherHistoryEntry
 */
export const VoucherHistoryEntry = new VoucherHistoryEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VoucherItem$Type extends MessageType<VoucherItem> {
    constructor() {
        super("wuxiaworld.api.v2.VoucherItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "isRedeemed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "userId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "enum", T: () => ["wuxiaworld.api.v2.VoucherItem.VoucherType", VoucherItem_VoucherType] },
            { no: 5, name: "createdAt", kind: "message", T: () => Timestamp },
            { no: 6, name: "redeemedAt", kind: "message", T: () => Timestamp },
            { no: 7, name: "completedMission", kind: "message", T: () => MissionItem },
            { no: 8, name: "chapterPurchase", kind: "message", T: () => UnlockedItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.VoucherItem
 */
export const VoucherItem = new VoucherItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.Vouchers
 */
export const Vouchers = new ServiceType("wuxiaworld.api.v2.Vouchers", [
    { name: "GetVoucherCounts", options: {}, I: Empty, O: GetVoucherCountsResponse },
    { name: "GetVoucherHistory", options: {}, I: GetVoucherHistoryRequest, O: GetVoucherHistoryResponse }
]);
