// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/notifications.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Notifications } from "./notifications";
import type { DeletePushNotificationTokenRequest } from "./notifications";
import type { RegisterPushNotificationTokenRequest } from "./notifications";
import type { SetNotificationSettingsRequest } from "./notifications";
import type { SetNotificationStatusesResponse } from "./notifications";
import type { SetNotificationStatusesRequest } from "./notifications";
import type { SetNotificationStatusResponse } from "./notifications";
import type { SetNotificationStatusRequest } from "./notifications";
import type { GetMyNotificationSettingsResponse } from "./notifications";
import type { Empty } from "../google/protobuf/empty";
import type { GetMyNotificationsCountResponse } from "./notifications";
import type { GetMyNotificationsCountRequest } from "./notifications";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetMyNotificationsResponse } from "./notifications";
import type { GetMyNotificationsRequest } from "./notifications";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service wuxiaworld.api.v2.Notifications
 */
export interface INotificationsClient {
    /**
     * @generated from protobuf rpc: GetMyNotifications(wuxiaworld.api.v2.GetMyNotificationsRequest) returns (wuxiaworld.api.v2.GetMyNotificationsResponse);
     */
    getMyNotifications(input: GetMyNotificationsRequest, options?: RpcOptions): UnaryCall<GetMyNotificationsRequest, GetMyNotificationsResponse>;
    /**
     * @generated from protobuf rpc: GetMyNotificationsCount(wuxiaworld.api.v2.GetMyNotificationsCountRequest) returns (wuxiaworld.api.v2.GetMyNotificationsCountResponse);
     */
    getMyNotificationsCount(input: GetMyNotificationsCountRequest, options?: RpcOptions): UnaryCall<GetMyNotificationsCountRequest, GetMyNotificationsCountResponse>;
    /**
     * @generated from protobuf rpc: GetMyNotificationSettings(google.protobuf.Empty) returns (wuxiaworld.api.v2.GetMyNotificationSettingsResponse);
     */
    getMyNotificationSettings(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetMyNotificationSettingsResponse>;
    /**
     * @generated from protobuf rpc: SetNotificationStatus(wuxiaworld.api.v2.SetNotificationStatusRequest) returns (wuxiaworld.api.v2.SetNotificationStatusResponse);
     */
    setNotificationStatus(input: SetNotificationStatusRequest, options?: RpcOptions): UnaryCall<SetNotificationStatusRequest, SetNotificationStatusResponse>;
    /**
     * @generated from protobuf rpc: SetNotificationStatuses(wuxiaworld.api.v2.SetNotificationStatusesRequest) returns (wuxiaworld.api.v2.SetNotificationStatusesResponse);
     */
    setNotificationStatuses(input: SetNotificationStatusesRequest, options?: RpcOptions): UnaryCall<SetNotificationStatusesRequest, SetNotificationStatusesResponse>;
    /**
     * @generated from protobuf rpc: SetNotificationSettings(wuxiaworld.api.v2.SetNotificationSettingsRequest) returns (google.protobuf.Empty);
     */
    setNotificationSettings(input: SetNotificationSettingsRequest, options?: RpcOptions): UnaryCall<SetNotificationSettingsRequest, Empty>;
    /**
     * @generated from protobuf rpc: RegisterPushNotificationToken(wuxiaworld.api.v2.RegisterPushNotificationTokenRequest) returns (google.protobuf.Empty);
     */
    registerPushNotificationToken(input: RegisterPushNotificationTokenRequest, options?: RpcOptions): UnaryCall<RegisterPushNotificationTokenRequest, Empty>;
    /**
     * @generated from protobuf rpc: DeletePushNotificationToken(wuxiaworld.api.v2.DeletePushNotificationTokenRequest) returns (google.protobuf.Empty);
     */
    deletePushNotificationToken(input: DeletePushNotificationTokenRequest, options?: RpcOptions): UnaryCall<DeletePushNotificationTokenRequest, Empty>;
}
/**
 * @generated from protobuf service wuxiaworld.api.v2.Notifications
 */
export class NotificationsClient implements INotificationsClient, ServiceInfo {
    typeName = Notifications.typeName;
    methods = Notifications.methods;
    options = Notifications.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetMyNotifications(wuxiaworld.api.v2.GetMyNotificationsRequest) returns (wuxiaworld.api.v2.GetMyNotificationsResponse);
     */
    getMyNotifications(input: GetMyNotificationsRequest, options?: RpcOptions): UnaryCall<GetMyNotificationsRequest, GetMyNotificationsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMyNotificationsRequest, GetMyNotificationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMyNotificationsCount(wuxiaworld.api.v2.GetMyNotificationsCountRequest) returns (wuxiaworld.api.v2.GetMyNotificationsCountResponse);
     */
    getMyNotificationsCount(input: GetMyNotificationsCountRequest, options?: RpcOptions): UnaryCall<GetMyNotificationsCountRequest, GetMyNotificationsCountResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMyNotificationsCountRequest, GetMyNotificationsCountResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMyNotificationSettings(google.protobuf.Empty) returns (wuxiaworld.api.v2.GetMyNotificationSettingsResponse);
     */
    getMyNotificationSettings(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetMyNotificationSettingsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetMyNotificationSettingsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetNotificationStatus(wuxiaworld.api.v2.SetNotificationStatusRequest) returns (wuxiaworld.api.v2.SetNotificationStatusResponse);
     */
    setNotificationStatus(input: SetNotificationStatusRequest, options?: RpcOptions): UnaryCall<SetNotificationStatusRequest, SetNotificationStatusResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetNotificationStatusRequest, SetNotificationStatusResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetNotificationStatuses(wuxiaworld.api.v2.SetNotificationStatusesRequest) returns (wuxiaworld.api.v2.SetNotificationStatusesResponse);
     */
    setNotificationStatuses(input: SetNotificationStatusesRequest, options?: RpcOptions): UnaryCall<SetNotificationStatusesRequest, SetNotificationStatusesResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetNotificationStatusesRequest, SetNotificationStatusesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetNotificationSettings(wuxiaworld.api.v2.SetNotificationSettingsRequest) returns (google.protobuf.Empty);
     */
    setNotificationSettings(input: SetNotificationSettingsRequest, options?: RpcOptions): UnaryCall<SetNotificationSettingsRequest, Empty> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetNotificationSettingsRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RegisterPushNotificationToken(wuxiaworld.api.v2.RegisterPushNotificationTokenRequest) returns (google.protobuf.Empty);
     */
    registerPushNotificationToken(input: RegisterPushNotificationTokenRequest, options?: RpcOptions): UnaryCall<RegisterPushNotificationTokenRequest, Empty> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<RegisterPushNotificationTokenRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePushNotificationToken(wuxiaworld.api.v2.DeletePushNotificationTokenRequest) returns (google.protobuf.Empty);
     */
    deletePushNotificationToken(input: DeletePushNotificationTokenRequest, options?: RpcOptions): UnaryCall<DeletePushNotificationTokenRequest, Empty> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeletePushNotificationTokenRequest, Empty>("unary", this._transport, method, opt, input);
    }
}
