// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/vouchers.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Vouchers } from "./vouchers";
import type { GetVoucherHistoryResponse } from "./vouchers";
import type { GetVoucherHistoryRequest } from "./vouchers";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetVoucherCountsResponse } from "./vouchers";
import type { Empty } from "../google/protobuf/empty";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service wuxiaworld.api.v2.Vouchers
 */
export interface IVouchersClient {
    /**
     * @generated from protobuf rpc: GetVoucherCounts(google.protobuf.Empty) returns (wuxiaworld.api.v2.GetVoucherCountsResponse);
     */
    getVoucherCounts(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetVoucherCountsResponse>;
    /**
     * @generated from protobuf rpc: GetVoucherHistory(wuxiaworld.api.v2.GetVoucherHistoryRequest) returns (wuxiaworld.api.v2.GetVoucherHistoryResponse);
     */
    getVoucherHistory(input: GetVoucherHistoryRequest, options?: RpcOptions): UnaryCall<GetVoucherHistoryRequest, GetVoucherHistoryResponse>;
}
/**
 * @generated from protobuf service wuxiaworld.api.v2.Vouchers
 */
export class VouchersClient implements IVouchersClient, ServiceInfo {
    typeName = Vouchers.typeName;
    methods = Vouchers.methods;
    options = Vouchers.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetVoucherCounts(google.protobuf.Empty) returns (wuxiaworld.api.v2.GetVoucherCountsResponse);
     */
    getVoucherCounts(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetVoucherCountsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetVoucherCountsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVoucherHistory(wuxiaworld.api.v2.GetVoucherHistoryRequest) returns (wuxiaworld.api.v2.GetVoucherHistoryResponse);
     */
    getVoucherHistory(input: GetVoucherHistoryRequest, options?: RpcOptions): UnaryCall<GetVoucherHistoryRequest, GetVoucherHistoryResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetVoucherHistoryRequest, GetVoucherHistoryResponse>("unary", this._transport, method, opt, input);
    }
}
