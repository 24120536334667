// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/pagination.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message wuxiaworld.api.v2.PageInfoRequest
 */
export interface PageInfoRequest {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 count = 2;
     */
    count: number;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.PageInfoResponse
 */
export interface PageInfoResponse {
    /**
     * @generated from protobuf field: int32 pageNumber = 1;
     */
    pageNumber: number;
    /**
     * @generated from protobuf field: int32 total = 2;
     */
    total: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class PageInfoRequest$Type extends MessageType<PageInfoRequest> {
    constructor() {
        super("wuxiaworld.api.v2.PageInfoRequest", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "wuxiaworld.api.v2.required": { value: true }, "wuxiaworld.api.v2.range": { min: { value: 1 } } } },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "wuxiaworld.api.v2.required": { value: true }, "wuxiaworld.api.v2.range": { min: { value: 1 }, max: { value: 100 } } } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.PageInfoRequest
 */
export const PageInfoRequest = new PageInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageInfoResponse$Type extends MessageType<PageInfoResponse> {
    constructor() {
        super("wuxiaworld.api.v2.PageInfoResponse", [
            { no: 1, name: "pageNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.PageInfoResponse
 */
export const PageInfoResponse = new PageInfoResponse$Type();
