// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/objects.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message wuxiaworld.api.v2.ObjectItem
 */
export interface ObjectItem {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ObjectType type = 1;
     */
    type: ObjectType;
    /**
     * @generated from protobuf field: int32 id = 2;
     */
    id: number;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.ObjectType
 */
export enum ObjectType {
    /**
     * @generated from protobuf enum value: UnknownObject = 0;
     */
    UnknownObject = 0,
    /**
     * @generated from protobuf enum value: NovelObject = 1;
     */
    NovelObject = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class ObjectItem$Type extends MessageType<ObjectItem> {
    constructor() {
        super("wuxiaworld.api.v2.ObjectItem", [
            { no: 1, name: "type", kind: "enum", T: () => ["wuxiaworld.api.v2.ObjectType", ObjectType] },
            { no: 2, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ObjectItem
 */
export const ObjectItem = new ObjectItem$Type();
