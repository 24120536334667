import { logGAEvent } from '@app/analytics';
import { useCompareMemo } from '@app/libs/hooks';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export type AnalyticsTrackingProps = {
    title?: string;
    disabled?: boolean;
    locationTracking?: boolean;
    extraProperties?: Record<string, any>;
};

export function AnalyticsTracking({ title, disabled, locationTracking, extraProperties }: AnalyticsTrackingProps) {
    const { pathname } = useLocation();
    const lastPath = useRef<string | undefined>();

    const extraProps = useCompareMemo(() => extraProperties ?? {}, [extraProperties]);

    const location = locationTracking === false ? undefined : pathname;

    useEffect(() => {
        const isBrowser = typeof window !== 'undefined';
        if (!isBrowser || disabled || lastPath.current === location) {
            return;
        }

        lastPath.current = location;

        logGAEvent('page_view', {
            page_title: title ?? document.title,
            page_location: window.location.href,
            ...extraProps,
        });
    }, [disabled, extraProps, location, title]);

    return null;
}
