// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/errors.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message wuxiaworld.api.v2.ApiErrorDetailItem
 */
export interface ApiErrorDetailItem {
    /**
     * @generated from protobuf field: string description = 1;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string errors = 2;
     */
    errors: string[];
    /**
     * @generated from protobuf field: map<string, string> metadata = 3;
     */
    metadata: {
        [key: string]: string;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class ApiErrorDetailItem$Type extends MessageType<ApiErrorDetailItem> {
    constructor() {
        super("wuxiaworld.api.v2.ApiErrorDetailItem", [
            { no: 1, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ApiErrorDetailItem
 */
export const ApiErrorDetailItem = new ApiErrorDetailItem$Type();
