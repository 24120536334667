// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/user_streaks.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { UserStreaks } from "./user_streaks";
import type { GetStreakLevelsResponse } from "./user_streaks";
import type { GetStreakLevelsRequest } from "./user_streaks";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetUserStreaksResponse } from "./user_streaks";
import type { GetUserStreaksRequest } from "./user_streaks";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service wuxiaworld.api.v2.UserStreaks
 */
export interface IUserStreaksClient {
    /**
     * @generated from protobuf rpc: GetUserStreaks(wuxiaworld.api.v2.GetUserStreaksRequest) returns (wuxiaworld.api.v2.GetUserStreaksResponse);
     */
    getUserStreaks(input: GetUserStreaksRequest, options?: RpcOptions): UnaryCall<GetUserStreaksRequest, GetUserStreaksResponse>;
    /**
     * @generated from protobuf rpc: GetStreakLevels(wuxiaworld.api.v2.GetStreakLevelsRequest) returns (wuxiaworld.api.v2.GetStreakLevelsResponse);
     */
    getStreakLevels(input: GetStreakLevelsRequest, options?: RpcOptions): UnaryCall<GetStreakLevelsRequest, GetStreakLevelsResponse>;
}
/**
 * @generated from protobuf service wuxiaworld.api.v2.UserStreaks
 */
export class UserStreaksClient implements IUserStreaksClient, ServiceInfo {
    typeName = UserStreaks.typeName;
    methods = UserStreaks.methods;
    options = UserStreaks.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetUserStreaks(wuxiaworld.api.v2.GetUserStreaksRequest) returns (wuxiaworld.api.v2.GetUserStreaksResponse);
     */
    getUserStreaks(input: GetUserStreaksRequest, options?: RpcOptions): UnaryCall<GetUserStreaksRequest, GetUserStreaksResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserStreaksRequest, GetUserStreaksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStreakLevels(wuxiaworld.api.v2.GetStreakLevelsRequest) returns (wuxiaworld.api.v2.GetStreakLevelsResponse);
     */
    getStreakLevels(input: GetStreakLevelsRequest, options?: RpcOptions): UnaryCall<GetStreakLevelsRequest, GetStreakLevelsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetStreakLevelsRequest, GetStreakLevelsResponse>("unary", this._transport, method, opt, input);
    }
}
