import type { AnalyticsPayment, AnalyticsPluginInstance } from './types';

declare global {
    interface Window {
        gtmDataLayer: any[];
    }
}

type GTMPluginConfig = {
    tagId?: string;
};

export type GTMPluginType = AnalyticsPluginInstance<Window['gtmDataLayer'], GTMPluginConfig>;

export class GTMPlugin implements AnalyticsPluginInstance<Window['gtmDataLayer'], GTMPluginConfig> {
    init({ tagId }: GTMPluginConfig) {
        if (typeof tagId === 'undefined') {
            throw new Error('Missing GTM tag id');
        }

        window.gtmDataLayer = window.gtmDataLayer || [];
    }

    onReady(action: (instance: object[]) => void): void {} // eslint-disable-line @typescript-eslint/no-unused-vars

    getInstance(): object[] {
        return window.gtmDataLayer;
    }

    trackEvent(eventName: string, properties?: Record<string, any>): void {
        const instance = this.getInstance();

        instance.push({
            event: eventName,
            ...properties,
        });
    }

    trackPayment(payment: AnalyticsPayment): void {
        this.trackEvent('Purchase', payment);
    }
}
