// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/subscriptions.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Subscriptions } from "./subscriptions";
import type { GetSubscriptionCostResponse } from "./subscriptions";
import type { GetSubscriptionCostRequest } from "./subscriptions";
import type { CancelSubscriptionResponse } from "./subscriptions";
import type { CancelSubscriptionRequest } from "./subscriptions";
import type { UpdateSubscriptionResponse } from "./subscriptions";
import type { UpdateSubscriptionRequest } from "./subscriptions";
import type { CreateSubscriptionResponse } from "./subscriptions";
import type { CreateSubscriptionRequest } from "./subscriptions";
import type { GetGatewaySubscriptionsResponse } from "./subscriptions";
import type { GetGatewaySubscriptionsRequest } from "./subscriptions";
import type { GetSubscriptionResponse } from "./subscriptions";
import type { GetSubscriptionRequest } from "./subscriptions";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetSubscriptionsResponse } from "./subscriptions";
import type { GetSubscriptionsRequest } from "./subscriptions";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service wuxiaworld.api.v2.Subscriptions
 */
export interface ISubscriptionsClient {
    /**
     * @generated from protobuf rpc: GetSubscriptions(wuxiaworld.api.v2.GetSubscriptionsRequest) returns (wuxiaworld.api.v2.GetSubscriptionsResponse);
     */
    getSubscriptions(input: GetSubscriptionsRequest, options?: RpcOptions): UnaryCall<GetSubscriptionsRequest, GetSubscriptionsResponse>;
    /**
     * @generated from protobuf rpc: GetSubscription(wuxiaworld.api.v2.GetSubscriptionRequest) returns (wuxiaworld.api.v2.GetSubscriptionResponse);
     */
    getSubscription(input: GetSubscriptionRequest, options?: RpcOptions): UnaryCall<GetSubscriptionRequest, GetSubscriptionResponse>;
    /**
     * @generated from protobuf rpc: GetGatewaySubscriptions(wuxiaworld.api.v2.GetGatewaySubscriptionsRequest) returns (wuxiaworld.api.v2.GetGatewaySubscriptionsResponse);
     */
    getGatewaySubscriptions(input: GetGatewaySubscriptionsRequest, options?: RpcOptions): UnaryCall<GetGatewaySubscriptionsRequest, GetGatewaySubscriptionsResponse>;
    /**
     * @generated from protobuf rpc: CreateSubscription(wuxiaworld.api.v2.CreateSubscriptionRequest) returns (wuxiaworld.api.v2.CreateSubscriptionResponse);
     */
    createSubscription(input: CreateSubscriptionRequest, options?: RpcOptions): UnaryCall<CreateSubscriptionRequest, CreateSubscriptionResponse>;
    /**
     * @generated from protobuf rpc: UpdateSubscription(wuxiaworld.api.v2.UpdateSubscriptionRequest) returns (wuxiaworld.api.v2.UpdateSubscriptionResponse);
     */
    updateSubscription(input: UpdateSubscriptionRequest, options?: RpcOptions): UnaryCall<UpdateSubscriptionRequest, UpdateSubscriptionResponse>;
    /**
     * @generated from protobuf rpc: CancelSubscription(wuxiaworld.api.v2.CancelSubscriptionRequest) returns (wuxiaworld.api.v2.CancelSubscriptionResponse);
     */
    cancelSubscription(input: CancelSubscriptionRequest, options?: RpcOptions): UnaryCall<CancelSubscriptionRequest, CancelSubscriptionResponse>;
    /**
     * @generated from protobuf rpc: GetSubscriptionCost(wuxiaworld.api.v2.GetSubscriptionCostRequest) returns (wuxiaworld.api.v2.GetSubscriptionCostResponse);
     */
    getSubscriptionCost(input: GetSubscriptionCostRequest, options?: RpcOptions): UnaryCall<GetSubscriptionCostRequest, GetSubscriptionCostResponse>;
}
/**
 * @generated from protobuf service wuxiaworld.api.v2.Subscriptions
 */
export class SubscriptionsClient implements ISubscriptionsClient, ServiceInfo {
    typeName = Subscriptions.typeName;
    methods = Subscriptions.methods;
    options = Subscriptions.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetSubscriptions(wuxiaworld.api.v2.GetSubscriptionsRequest) returns (wuxiaworld.api.v2.GetSubscriptionsResponse);
     */
    getSubscriptions(input: GetSubscriptionsRequest, options?: RpcOptions): UnaryCall<GetSubscriptionsRequest, GetSubscriptionsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSubscriptionsRequest, GetSubscriptionsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSubscription(wuxiaworld.api.v2.GetSubscriptionRequest) returns (wuxiaworld.api.v2.GetSubscriptionResponse);
     */
    getSubscription(input: GetSubscriptionRequest, options?: RpcOptions): UnaryCall<GetSubscriptionRequest, GetSubscriptionResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSubscriptionRequest, GetSubscriptionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetGatewaySubscriptions(wuxiaworld.api.v2.GetGatewaySubscriptionsRequest) returns (wuxiaworld.api.v2.GetGatewaySubscriptionsResponse);
     */
    getGatewaySubscriptions(input: GetGatewaySubscriptionsRequest, options?: RpcOptions): UnaryCall<GetGatewaySubscriptionsRequest, GetGatewaySubscriptionsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGatewaySubscriptionsRequest, GetGatewaySubscriptionsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateSubscription(wuxiaworld.api.v2.CreateSubscriptionRequest) returns (wuxiaworld.api.v2.CreateSubscriptionResponse);
     */
    createSubscription(input: CreateSubscriptionRequest, options?: RpcOptions): UnaryCall<CreateSubscriptionRequest, CreateSubscriptionResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateSubscriptionRequest, CreateSubscriptionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSubscription(wuxiaworld.api.v2.UpdateSubscriptionRequest) returns (wuxiaworld.api.v2.UpdateSubscriptionResponse);
     */
    updateSubscription(input: UpdateSubscriptionRequest, options?: RpcOptions): UnaryCall<UpdateSubscriptionRequest, UpdateSubscriptionResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSubscriptionRequest, UpdateSubscriptionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CancelSubscription(wuxiaworld.api.v2.CancelSubscriptionRequest) returns (wuxiaworld.api.v2.CancelSubscriptionResponse);
     */
    cancelSubscription(input: CancelSubscriptionRequest, options?: RpcOptions): UnaryCall<CancelSubscriptionRequest, CancelSubscriptionResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<CancelSubscriptionRequest, CancelSubscriptionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSubscriptionCost(wuxiaworld.api.v2.GetSubscriptionCostRequest) returns (wuxiaworld.api.v2.GetSubscriptionCostResponse);
     */
    getSubscriptionCost(input: GetSubscriptionCostRequest, options?: RpcOptions): UnaryCall<GetSubscriptionCostRequest, GetSubscriptionCostResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSubscriptionCostRequest, GetSubscriptionCostResponse>("unary", this._transport, method, opt, input);
    }
}
