import { create } from 'zustand';

interface HeaderStickyState {
    enabled: boolean;
    setEnabled: (enabled: boolean) => void;
}

export const useHeaderStickyStore = create<HeaderStickyState>()(set => ({
    enabled: false,
    setEnabled: (enabled: boolean) => set({ enabled }),
}));
