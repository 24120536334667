// @generated by protobuf-ts 2.9.4 with parameter optimize_code_size,long_type_number,generate_dependencies
// @generated from protobuf file "Protos/product_surveys.proto" (package "wuxiaworld.api.v2", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
import { StringValue } from "../google/protobuf/wrappers";
import { ProductType } from "./products";
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetProductSurveyForTypeRequest
 */
export interface GetProductSurveyForTypeRequest {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ProductType productType = 1;
     */
    productType: ProductType;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.GetProductSurveyForTypeResponse
 */
export interface GetProductSurveyForTypeResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ProductSurveyItem item = 1;
     */
    item?: ProductSurveyItem;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.AddProductSurveyRequest
 */
export interface AddProductSurveyRequest {
    /**
     * @generated from protobuf field: string surveyId = 1;
     */
    surveyId: string;
    /**
     * @generated from protobuf field: int32 productId = 2;
     */
    productId: number;
    /**
     * @generated from protobuf field: google.protobuf.StringValue data = 3;
     */
    data?: StringValue;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserProductSurvey.SubscriptionInfo subscriptionInfo = 4;
     */
    subscriptionInfo?: UserProductSurvey_SubscriptionInfo;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.AddProductSurveyRequest.AddSurveyAnswer answers = 5;
     */
    answers: AddProductSurveyRequest_AddSurveyAnswer[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.AddProductSurveyRequest.AddSurveyAnswer
 */
export interface AddProductSurveyRequest_AddSurveyAnswer {
    /**
     * @generated from protobuf field: string questionId = 1;
     */
    questionId: string;
    /**
     * @generated from protobuf field: string data = 2;
     */
    data: string;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.AddProductSurveyResponse
 */
export interface AddProductSurveyResponse {
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserProductSurvey userSurvey = 1;
     */
    userSurvey?: UserProductSurvey;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.UserProductSurvey
 */
export interface UserProductSurvey {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string userId = 2;
     */
    userId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 3;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updatedAt = 4;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ProductSurveyItem survey = 6;
     */
    survey?: ProductSurveyItem;
    /**
     * @generated from protobuf field: string answerData = 7;
     */
    answerData: string;
    /**
     * @generated from protobuf field: int32 productId = 8;
     */
    productId: number;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserProductSurvey.SubscriptionInfo subscriptionInfo = 9;
     */
    subscriptionInfo?: UserProductSurvey_SubscriptionInfo;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ProductSurveyAnswer answers = 10;
     */
    answers: ProductSurveyAnswer[];
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.UserProductSurvey.SubscriptionInfo
 */
export interface UserProductSurvey_SubscriptionInfo {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.UserProductSurvey.SurveySubscriptionType type = 2;
     */
    type: UserProductSurvey_SurveySubscriptionType;
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.UserProductSurvey.SurveySubscriptionType
 */
export enum UserProductSurvey_SurveySubscriptionType {
    /**
     * @generated from protobuf enum value: Vip = 0;
     */
    Vip = 0,
    /**
     * @generated from protobuf enum value: Sponsor = 1;
     */
    Sponsor = 1,
    /**
     * @generated from protobuf enum value: Unknown = -1;
     */
    Unknown = -1
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ProductSurveyQuestion
 */
export interface ProductSurveyQuestion {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string question = 2;
     */
    question: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue description = 3;
     */
    description?: StringValue;
    /**
     * @generated from protobuf field: int32 order = 4;
     */
    order: number;
    /**
     * @generated from protobuf field: string data = 5;
     */
    data: string;
    /**
     * @generated from protobuf field: string surveyId = 6;
     */
    surveyId: string;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ProductSurveyQuestionType type = 7;
     */
    type: ProductSurveyQuestionType;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ProductSurveyAnswer
 */
export interface ProductSurveyAnswer {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string questionId = 2;
     */
    questionId: string;
    /**
     * @generated from protobuf field: string data = 3;
     */
    data: string;
}
/**
 * @generated from protobuf message wuxiaworld.api.v2.ProductSurveyItem
 */
export interface ProductSurveyItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue description = 3;
     */
    description?: StringValue;
    /**
     * @generated from protobuf field: wuxiaworld.api.v2.ProductType productType = 4;
     */
    productType: ProductType;
    /**
     * @generated from protobuf field: bool isMultipleEntriesAllowed = 5;
     */
    isMultipleEntriesAllowed: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 6;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updatedAt = 7;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: repeated wuxiaworld.api.v2.ProductSurveyQuestion questions = 8;
     */
    questions: ProductSurveyQuestion[];
}
/**
 * @generated from protobuf enum wuxiaworld.api.v2.ProductSurveyQuestionType
 */
export enum ProductSurveyQuestionType {
    /**
     * @generated from protobuf enum value: Text = 0;
     */
    Text = 0,
    /**
     * @generated from protobuf enum value: Number = 1;
     */
    Number = 1,
    /**
     * @generated from protobuf enum value: MultipleChoice = 2;
     */
    MultipleChoice = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class GetProductSurveyForTypeRequest$Type extends MessageType<GetProductSurveyForTypeRequest> {
    constructor() {
        super("wuxiaworld.api.v2.GetProductSurveyForTypeRequest", [
            { no: 1, name: "productType", kind: "enum", T: () => ["wuxiaworld.api.v2.ProductType", ProductType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetProductSurveyForTypeRequest
 */
export const GetProductSurveyForTypeRequest = new GetProductSurveyForTypeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProductSurveyForTypeResponse$Type extends MessageType<GetProductSurveyForTypeResponse> {
    constructor() {
        super("wuxiaworld.api.v2.GetProductSurveyForTypeResponse", [
            { no: 1, name: "item", kind: "message", T: () => ProductSurveyItem }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.GetProductSurveyForTypeResponse
 */
export const GetProductSurveyForTypeResponse = new GetProductSurveyForTypeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddProductSurveyRequest$Type extends MessageType<AddProductSurveyRequest> {
    constructor() {
        super("wuxiaworld.api.v2.AddProductSurveyRequest", [
            { no: 1, name: "surveyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "productId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "data", kind: "message", T: () => StringValue },
            { no: 4, name: "subscriptionInfo", kind: "message", T: () => UserProductSurvey_SubscriptionInfo },
            { no: 5, name: "answers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AddProductSurveyRequest_AddSurveyAnswer }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.AddProductSurveyRequest
 */
export const AddProductSurveyRequest = new AddProductSurveyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddProductSurveyRequest_AddSurveyAnswer$Type extends MessageType<AddProductSurveyRequest_AddSurveyAnswer> {
    constructor() {
        super("wuxiaworld.api.v2.AddProductSurveyRequest.AddSurveyAnswer", [
            { no: 1, name: "questionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.AddProductSurveyRequest.AddSurveyAnswer
 */
export const AddProductSurveyRequest_AddSurveyAnswer = new AddProductSurveyRequest_AddSurveyAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddProductSurveyResponse$Type extends MessageType<AddProductSurveyResponse> {
    constructor() {
        super("wuxiaworld.api.v2.AddProductSurveyResponse", [
            { no: 1, name: "userSurvey", kind: "message", T: () => UserProductSurvey }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.AddProductSurveyResponse
 */
export const AddProductSurveyResponse = new AddProductSurveyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserProductSurvey$Type extends MessageType<UserProductSurvey> {
    constructor() {
        super("wuxiaworld.api.v2.UserProductSurvey", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "createdAt", kind: "message", T: () => Timestamp },
            { no: 4, name: "updatedAt", kind: "message", T: () => Timestamp },
            { no: 6, name: "survey", kind: "message", T: () => ProductSurveyItem },
            { no: 7, name: "answerData", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "productId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "subscriptionInfo", kind: "message", T: () => UserProductSurvey_SubscriptionInfo },
            { no: 10, name: "answers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProductSurveyAnswer }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.UserProductSurvey
 */
export const UserProductSurvey = new UserProductSurvey$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserProductSurvey_SubscriptionInfo$Type extends MessageType<UserProductSurvey_SubscriptionInfo> {
    constructor() {
        super("wuxiaworld.api.v2.UserProductSurvey.SubscriptionInfo", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["wuxiaworld.api.v2.UserProductSurvey.SurveySubscriptionType", UserProductSurvey_SurveySubscriptionType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.UserProductSurvey.SubscriptionInfo
 */
export const UserProductSurvey_SubscriptionInfo = new UserProductSurvey_SubscriptionInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductSurveyQuestion$Type extends MessageType<ProductSurveyQuestion> {
    constructor() {
        super("wuxiaworld.api.v2.ProductSurveyQuestion", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "question", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "message", T: () => StringValue },
            { no: 4, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "surveyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "type", kind: "enum", T: () => ["wuxiaworld.api.v2.ProductSurveyQuestionType", ProductSurveyQuestionType] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ProductSurveyQuestion
 */
export const ProductSurveyQuestion = new ProductSurveyQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductSurveyAnswer$Type extends MessageType<ProductSurveyAnswer> {
    constructor() {
        super("wuxiaworld.api.v2.ProductSurveyAnswer", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "questionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ProductSurveyAnswer
 */
export const ProductSurveyAnswer = new ProductSurveyAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductSurveyItem$Type extends MessageType<ProductSurveyItem> {
    constructor() {
        super("wuxiaworld.api.v2.ProductSurveyItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "message", T: () => StringValue },
            { no: 4, name: "productType", kind: "enum", T: () => ["wuxiaworld.api.v2.ProductType", ProductType] },
            { no: 5, name: "isMultipleEntriesAllowed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "createdAt", kind: "message", T: () => Timestamp },
            { no: 7, name: "updatedAt", kind: "message", T: () => Timestamp },
            { no: 8, name: "questions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProductSurveyQuestion }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message wuxiaworld.api.v2.ProductSurveyItem
 */
export const ProductSurveyItem = new ProductSurveyItem$Type();
/**
 * @generated ServiceType for protobuf service wuxiaworld.api.v2.ProductSurveys
 */
export const ProductSurveys = new ServiceType("wuxiaworld.api.v2.ProductSurveys", [
    { name: "GetProductSurveyForType", options: {}, I: GetProductSurveyForTypeRequest, O: GetProductSurveyForTypeResponse },
    { name: "AddProductSurvey", options: {}, I: AddProductSurveyRequest, O: AddProductSurveyResponse }
]);
