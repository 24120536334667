import { VoucherItem_VoucherType } from '@app/_proto/Protos/vouchers';
import { VouchersClient } from '@app/_proto/Protos/vouchers.client';
import { Empty } from '@app/_proto/google/protobuf/empty';
import { useGrpcApiWithQuery } from '@app/libs/api';
import { useAuth } from '@app/libs/auth';

type UseMyVouchersReturn = {
    allVoucherCounts: number | undefined;
    chaptersVoucherCounts: number | undefined;
    ebooksVoucherCounts: number | undefined;
    isLoading: boolean;
    refetch: any; // TODO: proper typing required.
};

export default function useMyVouchers(): UseMyVouchersReturn {
    const { user } = useAuth();

    const { data, isLoading, refetch } = useGrpcApiWithQuery(
        VouchersClient,
        c => c.getVoucherCounts,
        Empty.create(),
        ['vouchers', user?.id],
        {
            enabled: !!user,
            cacheTime: 0, //Don't cache
            refetchOnWindowFocus: true,
        }
    );

    return {
        allVoucherCounts: data?.voucherCounts[VoucherItem_VoucherType.VoucherTypeAll],
        chaptersVoucherCounts: data?.voucherCounts[VoucherItem_VoucherType.VoucherTypeChapters],
        ebooksVoucherCounts: data?.voucherCounts[VoucherItem_VoucherType.VoucherTypeEbooks],
        isLoading,
        refetch,
    };
}
